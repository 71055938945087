<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入项目会议名查询" v-model="queryInfo.issue" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="16">
                            <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">添加项目会议</el-button>

                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="issue"
                                label="会议名称"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="place"
                                label="会议地点"
                                width="200"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="startTime"
                                label="开始时间"
                                sortable
                                width="120"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="meetingPlan"
                                label="会议计划"
                                width="220"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="attendees"
                                label="会议概述"
                                width="220"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>

                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="addMeeting(scope.row.regulateMeetingId)" icon="el-icon-edit-outline" size="mini">会议纪要</el-button>
                                <el-button  type="primary" @click="beforeEdit(scope.row.regulateMeetingId)" icon="el-icon-edit" size="mini">更新</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.regulateMeetingId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增项目会议"
                :visible.sync="dialogVisible"
                width="45%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="addRules">
                <el-form-item label="会议名称" prop="issue">
                    <el-input v-model="addForm.issue"></el-input>
                </el-form-item>
                <el-form-item label="会议地点" prop="place">
                    <el-input v-model="addForm.place"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="会议开始时间" prop="startTime">
                            <el-date-picker
                                    v-model="addForm.startTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                                    >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="会议结束时间" prop="endTime">
                            <el-date-picker
                                    v-model="addForm.endTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                                   >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="会议主持人员" prop="presidedUserId">
                            <el-cascader
                                    :options="yonghu"
                                    :props="props"
                                    v-model="addForm.presidedUserId"
                                    @change="handleChange"
                                    clearable>
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="记录人员" prop="recordUserId">
                            <el-cascader
                                    :options="yonghu"
                                    :props="props"
                                    @change="handleChange2"
                                    v-model="addForm.recordUserId"
                                    clearable>
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="会议所属计划" prop="meetingPlanId">
                            <el-select v-model="addForm.meetingPlanId" placeholder="请选择" @change="changeYear">
                                <el-option
                                        v-for="item in optionsYear"
                                        :key="item.meetingPlanId"
                                        :label="item.annual"
                                        :value="item.meetingPlanId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属月度计划" prop="meetingPlanTableId">
                            <el-select v-model="addForm.meetingPlanTableId" placeholder="请选择">
                                <el-option
                                        v-for="item in optionsMonth"
                                        :key="item.meetingPlanTableId"
                                        :label="item.planName"
                                        :value="item.meetingPlanTableId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="会议计划" prop="meetingPlan">
                    <el-input  type="textarea" :rows="3"  v-model="addForm.meetingPlan"></el-input>
                </el-form-item>
                <el-form-item label="会议概述" prop="attendees">
                    <el-input  type="textarea" :rows="3"  v-model="addForm.attendees"></el-input>
                </el-form-item>
                <el-form-item label="缺席人员及事由" prop="absentReasons">
                    <el-input  type="textarea" :rows="3"  v-model="addForm.absentReasons"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改项目会议"
                :visible.sync="dialogVisibleEdit"
                width="45%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="editForm" label-width="120px" :rules="addRules">
                <el-form-item label="会议名称" prop="issue">
                    <el-input v-model="editForm.issue"></el-input>
                </el-form-item>
                <el-form-item label="会议地点" prop="place">
                    <el-input v-model="editForm.place"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="会议开始时间" prop="startTime">
                            <el-date-picker
                                    v-model="editForm.startTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="会议结束时间" prop="endTime">
                            <el-date-picker
                                    v-model="editForm.endTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="会议主持人员" prop="presidedUserId">
                            <el-cascader
                                    :options="yonghu"
                                    :props="props"
                                    v-model="editForm.presidedUserId"
                                    @change="handleChangeEdit"
                                    clearable>
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="记录人员" prop="recordUserId">
                            <el-cascader
                                    :options="yonghu"
                                    :props="props"
                                    @change="handleChange2Edit"
                                    v-model="editForm.recordUserId"
                                    clearable>
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="会议所属计划" prop="meetingPlanId">
                            <el-select v-model="editForm.meetingPlanId" placeholder="请选择" @change="changeYear">
                                <el-option
                                        v-for="item in optionsYear"
                                        :key="item.meetingPlanId"
                                        :label="item.annual"
                                        :value="item.meetingPlanId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属月度计划" prop="meetingPlanTableId">
                            <el-select v-model="editForm.meetingPlanTableId" placeholder="请选择">
                                <el-option
                                        v-for="item in optionsMonth"
                                        :key="item.meetingPlanTableId"
                                        :label="item.planName"
                                        :value="item.meetingPlanTableId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="会议计划" prop="meetingPlan">
                    <el-input  type="textarea" :rows="3"  v-model="editForm.meetingPlan"></el-input>
                </el-form-item>
                <el-form-item label="会议概述" prop="attendees">
                    <el-input  type="textarea" :rows="3"  v-model="editForm.attendees"></el-input>
                </el-form-item>
                <el-form-item label="缺席人员及事由" prop="absentReasons">
                    <el-input  type="textarea" :rows="3"  v-model="editForm.absentReasons"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="会议纪要"
                :visible.sync="dialogVisible2"
                width="50%"
                center
                :close-on-click-modal="false"
        >
            <el-descriptions  :column="1" >
                <el-descriptions-item label="会议名称">{{addForm2.issue}}</el-descriptions-item>
                <el-descriptions-item label="会议地点">{{addForm2.place}}</el-descriptions-item>
                <el-descriptions-item label="开始时间">{{addForm2.startTime}}</el-descriptions-item>
                <el-descriptions-item label="会议计划">{{addForm2.meetingPlan}}</el-descriptions-item>
                <el-descriptions-item label="会议概述">{{addForm2.attendees}}</el-descriptions-item>
                <el-descriptions-item label="缺席人员及事由">{{addForm2.absentReasons}} </el-descriptions-item>
                <el-descriptions-item label="会议内容"><el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请输入会议内容"
                        v-model="addForm2.account">
                </el-input>
                </el-descriptions-item>
                <hr/>
                <el-descriptions-item label="会议附件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit"
                            :on-change="filechangeEdit"
                            :file-list="fileListEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                    </el-upload>
                </el-descriptions-item>
            </el-descriptions>

            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible2 = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addMeetFiles" >确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "meeting",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    issue:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    departmentId:"",
                },
                yearInfo:{
                    annual:'',
                    pageNum:1,
                    pageSize:99999,
                    projectId:this.$root.proId,
                    departmentId:"",
                },
                total:0,
                dialogVisible:false,
                dialogVisible2:false,
                addForm:{
                    meetingPlan:'',
                    absentReasons:"",
                    account:'',
                    attendees:'',
                    createTime:'',
                    endTime:"",
                    issue:'',
                    meetingPlanId:'',
                    meetingPlanTableId:'',
                    projectId:this.$root.proId,
                    place:'',
                    presidedUserId:'',
                    recordUserId:'',
                    startTime:'',
                },
                editForm:{

                },
                dialogVisibleEdit:false,
                fileListEdit:[],
                addForm2:{
                    regulateMeetingId:'',
                    regulateRapexId:'',
                    account:'',
                    meetingPlan:'',
                    absentReasons:"",
                    attendees:'',
                    createTime:'',
                    endTime:"",
                    issue:'',
                    meetingPlanId:'',
                    meetingPlanTableId:'',
                    place:'',
                    presidedUserId:'',
                    recordUserId:'',
                    startTime:'',
                },
                addFormRule:{

                },
                yonghu:[],
                props: {
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                props2: {
                    multiple: true,
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                fileList:[],
                addRules:{

                },
                optionsYear:[],
                optionsMonth:[],
                fileListShoukuanEditDeleteID:[],
                fileLisShoukuantEditAdd:[]
            }
        },
        created(){
            this.getFiles()
            this.getUsers()
            this.getYearOptions()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询项目会议失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            async getYearOptions(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/plan/findPages',this.yearInfo)
                this.optionsYear=res.data.result
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addFile(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/save',this.addForm)
                if(res.status!='6000'){
                    return this.$message.error('新增项目会议失败！'+res.message)
                }
                this.$message.success('新增项目会议成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async addMeetFiles(){
                const {data:res}= await this.$http14.put('/v1.0/security/meeting/update',this.addForm2)
                if(res.status!='6010'){
                    return this.$message.error('更新会议纪要失败！'+res.message)
                }
                console.log(this.fileListShoukuanEditDeleteID)
                for(let i in this.fileListShoukuanEditDeleteID){
                    const {data:res2}= await this.$http14.delete('/v1.0/security/meeting/annex/delete/'+this.fileListShoukuanEditDeleteID[i])
                    if(res2.status!='4000'){
                        return this.$message.error('删除会议文件失败！'+res2.message)
                    }
                }
                if(this.fileLisShoukuantEditAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileLisShoukuantEditAdd){
                        formData2.append("multipartFiles",this.fileLisShoukuantEditAdd[j].raw);
                    }
                    console.log(this.addForm2)
                    formData2.append("regulateMeetingId",this.addForm2.regulateMeetingId)
                    const {data:res3}= await this.$http14.post('/v1.0/security/meeting/annex/save',formData2)
                    if(res3.status!='6000'){
                        return this.$message.error('添加会议文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改会议纪要成功！');
                this.dialogVisible2=false;
                this.getFiles();
            },
           async  changeYear(row){
                this.addForm.meetingPlanTableId=''
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/planTable/findAllByMeetingPlanId?meetingPlanId='+row)
                if(res.status!='3000'){
                    return  this.optionsMonth=[]
                }else {
                    this.optionsMonth=res.data
                }
            },
            handleChange(value){
                this.addForm.presidedUserId=value[value.length-1]
            },
            handleChangeEdit(value){
                this.editForm.presidedUserId=value[value.length-1]
            },
            handleChange2(value){
                this.addForm.recordUserId=value[value.length-1]
            },
            handleChange2Edit(value){
                this.editForm.recordUserId=value[value.length-1]
            },
            async addMeeting(id){
                this.fileListEdit=[]
                this.addForm2.regulateRapexId=id
                const {data:res}= await this.$http14.get('/v1.0/security/meeting/findById?id='+id)
                if(res.status!=='3000'){
                    return this.$message.error('查询会议纪要失败！'+res.message)
                }
                this.addForm2=res.data
                const {data:res2}= await this.$http14.post('/v1.0/security/meeting/annex/findAllByRegulateMeetingId?regulateMeetingId='+id)
                if(res2.status!=='3000'){
                   return  this.$message.error('查询会议纪要附件失败！'+res2.message)
                }
                for(let i in res2.data){
                    this.fileListEdit.push({id:res2.data[i].regulateMeetingAnnexId,name:res2.data[i].annexName,url:'http://localhost:8807/'+res2.data[i].annexPath})
                }
                this.fileListShoukuanEditDeleteID=[]
                this.fileLisShoukuantEditAdd=[]
                this.dialogVisible2=true
            },
            async beforeEdit(id){
                const {data:res}= await this.$http14.get('/v1.0/security/meeting/findById?id='+id)
                if(res.status!=='3000'){
                    return this.$message.error('查询项目会议失败！'+res.message)
                }
                const {data:res2}= await this.$http14.post('/v1.0/security/meeting/planTable/findAllByMeetingPlanId?meetingPlanId='+res.data.meetingPlanId)
                if(res2.status!='3000'){
                    return  this.optionsMonth=[]
                }else {
                    this.optionsMonth=res2.data
                }
                this.editForm=res.data
                this.dialogVisibleEdit=true
            },
            async editFile(){
                const {data:res}= await this.$http14.put('/v1.0/security/meeting/update',this.editForm)
                if(res.status!=='6010'){
                   return  this.$message.error('更新项目会议失败！'+res.message)
                }
                this.$message.success('更新项目会议成功！')
                this.dialogVisibleEdit=false
                this.getFiles()
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目会议, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/meeting/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除项目会议失败！'+res.message)
                }
                this.$message.success('删除项目会议成功！');
                this.getFiles();
            },
            async showfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getFiles()
            },
            handleRemove(file, fileList) {
                this.fileList=fileList
            },
            filechange(file,fileList){
                this.fileList=fileList
            },
            handleRemoveEdit(file, fileList) {
                console.log(file)
                if(file.id){
                    this.fileListShoukuanEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileLisShoukuantEditAdd.length; i++) {
                        if (this.fileLisShoukuantEditAdd[i] == file) {
                            this.fileLisShoukuantEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            filechangeEdit(file,fileList){
                this.fileLisShoukuantEditAdd.push(file)
                this.fileListEdit=fileList
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">

    /deep/ .el-date-editor{
        width: 100% !important;
    }
    /deep/ .el-descriptions-item__content{
        width: 80%;
    }
    /deep/ .el-descriptions-item__container{
        font-size: 17px;
    }
    /deep/ .el-descriptions-row{
        height: 60px;
    }
    /deep/ .el-descriptions-item__content{
        padding-left: 15px;
    }
</style>
