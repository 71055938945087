<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入产品名查询" v-model="queryInfo.productName" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="16">
                            <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">产品登记</el-button>

                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="productName"
                                label="产品名称"
                                width="160"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="productNumber"
                                label="产品编号"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="afetyProductTypeId"
                                label="产品种类"
                                sortable
                                width="120"
                                :show-overflow-tooltip="true"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.afetyProductTypeId==1">意外保险</span>
                                <span v-if="scope.row.afetyProductTypeId==2">责任保险</span>
                                <span v-if="scope.row.afetyProductTypeId==3">其它保险</span>
                                <span v-if="scope.row.afetyProductTypeId==4">劳务保障</span>
                                <span v-if="scope.row.afetyProductTypeId==5">防护措施</span>
                                <span v-if="scope.row.afetyProductTypeId==6">宣传演练</span>
                                <span v-else>其它</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="productStatus"
                                label="产品状态"
                                width="100"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.productStatus==1">正常</span>
                                <span v-else>禁用</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="periodValidity"
                                label="有限期限"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="productPrice"
                                label="产品价格"
                                width="105"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="productionDate"
                                label="采购时间"
                                width="105"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="productRemarks"
                                label="产品详情"
                                width="180"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="fileUp(scope.row.afetyProductionInfoId)" icon="el-icon-eleme" size="mini">附件</el-button>
                              <!--  <el-button  type="primary" @click="editMsg(scope.row.afetyProductionInfoId)" icon="el-icon-delete" size="mini">修改</el-button>-->
                                <el-button  type="primary" @click="deleteMsg(scope.row.afetyProductionInfoId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="产品登记"
                :visible.sync="dialogVisible"
                width="35%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addRules">
                <el-form-item label="产品名称" prop="productName">
                    <el-input v-model="addForm.productName"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="产品编号" prop="productNumber">
                            <el-input v-model="addForm.productNumber"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="产品种类" prop="afetyProductTypeId">
                            <el-select v-model="addForm.afetyProductTypeId" placeholder="请选择产品种类">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有限期限" prop="periodValidity">
                            <el-input v-model="addForm.periodValidity"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="产品状态" prop="productStatus">
                            <el-select v-model="addForm.productStatus" placeholder="请选择演练类别">
                                <el-option
                                        v-for="item in options2"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="产品价格" prop="productPrice">
                            <el-input v-model="addForm.productPrice"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="采购时间" prop="productionDate">
                            <el-date-picker
                                    v-model="addForm.productionDate"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="产品详情" prop="productRemarks">
                    <el-input  type="textarea" :rows="3"  v-model="addForm.productRemarks"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改产品登记"
                :visible.sync="dialogVisibleEdit"
                width="35%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="editFrom" label-width="80px" :rules="addRules">
                <el-form-item label="产品名称" prop="productName">
                    <el-input v-model="editFrom.productName"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="产品编号" prop="productNumber">
                            <el-input v-model="editFrom.productNumber"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="产品种类" prop="afetyProductTypeId">
                            <el-select v-model="editFrom.afetyProductTypeId" placeholder="请选择产品种类">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有限期限" prop="periodValidity">
                            <el-input v-model="editFrom.periodValidity"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="产品状态" prop="productStatus">
                            <el-select v-model="editFrom.productStatus" placeholder="请选择演练类别">
                                <el-option
                                        v-for="item in options2"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="产品价格" prop="productPrice">
                            <el-input v-model="editFrom.productPrice"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="采购时间" prop="productionDate">
                            <el-date-picker
                                    v-model="editFrom.productionDate"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    align="right"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="产品详情" prop="productRemarks">
                    <el-input  type="textarea" :rows="3"  v-model="editFrom.productRemarks"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="产品登记附件"
                :visible.sync="dialogVisible3"
                width="35%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef"  label-width="120px" >
                <el-form-item label="产品登记附件" >
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit"
                            :on-change="filechangeEdit"
                            :file-list="fileListEdit"
                            :on-preview="handlePreview"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible3 = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editFile2" >确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "drill",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    productName:'',
                    productStatus:'',
                    createTime:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    departmentId:''
                },
                total:0,
                dialogVisible:false,
                addForm:{
                    afetyProductTypeId:'',
                    afetyProductionInfoId:'',
                    createTime:'',
                    departmentId:"",
                    periodValidity:'',
                    projectId:this.$root.proId,
                    productName:'',
                    productNumber:'',
                    productPrice:null,
                    productRemarks:'',
                    productionDate:'',
                    productStatus:null,
                    userId:''
                },
                dialogVisibleEdit:false,
                editFrom:{

                },
                fileList:[],
                options:[{
                    value: 1,
                    label: '意外保险'
                },{
                    value: 2,
                    label: '责任保险'
                },{
                    value: 3,
                    label: '其它保险'
                },{
                    value: 4,
                    label: '劳务保障'
                },{
                    value: 5,
                    label: '防护措施'
                },{
                    value: 6,
                    label: '宣传演练'
                },{
                    value: 7,
                    label: '其它'
                }],
                options2:[{
                    value: 1,
                    label: '正常'
                },{
                    value: 2,
                    label: '禁用'
                }],
                addRules:{

                },
                fileListShoukuanEditDeleteID:[],
                fileLisShoukuantEditAdd:[],
                fileListEdit:[],
                dialogVisible3:false,
                drillsId:''
            }
        },
        created(){
            this.getFiles()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/productionInfo/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询产品登记失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addFile(){
                const {data:res}= await this.$http14.post('/v1.0/security/productionInfo/save',this.addForm)
                if(res.status!='6000'){
                    return this.$message.error('产品登记失败！'+res.message)
                }
                this.$message.success('产品登记成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async editFile(){
                console.log(this.editFrom)
                const {data:res}= await this.$http14.post('/v1.0/security/drills/update',this.editFrom)
                if(res.status!='6010'){
                    return this.$message.error('修改产品登记失败！'+res.message)
                }
                this.$message.success('修改产品登记成功！');
                this.dialogVisibleEdit=false;
                this.getFiles();
            },
            async editMsg(id){
                const {data:res}= await this.$http14.get('/v1.0/security/drills/findById?id='+id)
                if(res.status!='3000'){
                    return this.$message.error('查询产品登记失败！'+res.message)
                }
                this.editFrom=res.data
                this.dialogVisibleEdit=true
            },
            async fileUp(id){
                this.fileListEdit=[]
                const {data:res2}= await this.$http14.post('/v1.0/security/productionInfo/annex/findAllByAfetyProductionInfoId?afetyProductionInfoId='+id)
                if(res2.status=='3000'){
                    for(let i in res2.data){
                        this.fileListEdit.push({id:res2.data[i].afetyProductionInfoAnnexId,name:res2.data[i].infoName,url:'http://localhost:8807/'+res2.data[i].infoPath})
                    }
                }
                this.drillsId=id
                this.fileListShoukuanEditDeleteID=[]
                this.fileLisShoukuantEditAdd=[]
                this.dialogVisible3=true
            },
            async editFile2(){
                for(let i in this.fileListShoukuanEditDeleteID){
                    const {data:res2}= await this.$http14.delete('/v1.0/security/productionInfo/annex/delete/'+this.fileListShoukuanEditDeleteID[i])
                    if(res2.status!='4000'){
                        return this.$message.error('删除产品登记附件失败！'+res2.message)
                    }
                }
                if(this.fileLisShoukuantEditAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileLisShoukuantEditAdd){
                        formData2.append("multipartFiles",this.fileLisShoukuantEditAdd[j].raw);
                    }
                    formData2.append("afetyProductionInfoId",this.drillsId)
                    const {data:res3}= await this.$http14.post('/v1.0/security/productionInfo/annex/save',formData2)
                    if(res3.status!='6000'){
                        return this.$message.error('添加产品登记附件失败！'+res3.message)
                    }
                }
                this.$message.success('修改产品登记附件成功！');
                this.dialogVisible3=false;
                this.getFiles();
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该产品登记, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/productionInfo/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除产品登记失败！'+res.message)
                }
                this.$message.success('删除产品登记成功！');
                this.getFiles();
            },
            async showfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=filelist
            },
            async handlePreview(file) {
                var s=file.url.replace(file.url.match(/([^\/]*\/){2}([^\/]*)/)[0],'')
               const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+s.slice(1))
                window.open(res2.data)
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getFiles()
            },
            handleRemoveEdit(file, fileList) {
                console.log(file)
                if(file.id){
                    this.fileListShoukuanEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileLisShoukuantEditAdd.length; i++) {
                        if (this.fileLisShoukuantEditAdd[i] == file) {
                            this.fileLisShoukuantEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            filechangeEdit(file,fileList){
                this.fileLisShoukuantEditAdd.push(file)
                this.fileListEdit=fileList
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">


</style>
