<template>
  <div class="bg">
      <pro-left></pro-left>
      <div class="mainpart">
          <el-card class="box-card" >
              <div slot="header" class="btnheader" >
                  <el-button   @click="dialogVisible = true;isAdd=true;someData={}" v-has="'addevent'">新增进度计划审批</el-button>
              </div>
              <div class="serverTable">
                  <el-table
                          :data="tableData"
                          height="100%"
                          border
                          :header-cell-style="{background:'#e3ecfb',color:'black'}"
                        >
                      <el-table-column
                              type="index"
                              label="序号"
                              width="50"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="stage"
                              label="阶段"
                              width="100"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="host"
                              label="主持人"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="meetingLocation"
                              label="会议地点"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="meetingDate"
                              label="会议日期"
                              width="170"
                      >
                      <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                times.convertUTCTimeToLocalTime(scope.row.appointmentDate)
                            }}</span>
                        </div>
                    </template>
                      </el-table-column>
                      <el-table-column
                              prop="creatorName"
                              label="创建人"
                              align="center"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="createdTime"
                              label="创建时间"
                              align="center"
                              width="170"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="verifyStatus"
                              label="流程状态"
                              align="center"
                              width="100"
                      >
                          <template v-slot="scope">
                            <span v-if="scope.row.verifyStatus==null||scope.row.verifyStatus==0">制单</span>
                            <span v-else-if="scope.row.verifyStatus==1">提交审批</span>
                            <span v-else-if="scope.row.verifyStatus==2">审批通过</span>
                            <span v-else-if="scope.row.verifyStatus==3">审批不通过</span>
                            <span v-else-if="scope.row.verifyStatus==4">上报不审批</span>
                            <span v-else-if="scope.row.verifyStatus==-1">未填报</span>
                          </template>
                      </el-table-column>
                      <el-table-column
                              label="操作"
                              align="center"
                      >
                          <template v-slot="scope">
                              <el-button  type="primary" @click="getInfo(scope.row.id)"  size="mini">详情</el-button>
                              <el-button  type="primary" @click="faqi(scope.row.id)" size="mini" v-has="'editevent'">发起</el-button>
                              <el-button  type="primary" @click="deleteMsg(scope.row.id)" size="mini" v-has="'delevent'">删除</el-button>
                              <el-button  type="primary" @click="upFile(scope.row.id)"  size="mini" v-has="'delevent'">附件上传</el-button>
                              <el-button  type="primary" @click="getFiles(scope.row.id)"  size="mini" v-has="'delevent'">附件下载</el-button>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
          </el-card>
      </div>
      <el-dialog
              title="进度计划审批表"
              :visible.sync="dialogVisible"
              width="43%"
              center
              :close-on-click-modal="false"
      >
      <processPlanAdd ref="childProAppRef" :someData="someData" :isAdd="isAdd"></processPlanAdd>
          <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
  <el-button round type="goon" size="medium"  @click="addproApp" >确 定</el-button>
</span>
      </el-dialog>
      <el-dialog
        title="附件上传"
        :visible.sync="dialogVisibleFile"
        width="30%"
        :close-on-click-modal=false>
            <el-form ref="addFormRef"  label-width="140px" >
                <el-form-item label="进度计划">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :on-change="filechange"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="进度计划审批表">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :on-change="filechange2"
                        :on-remove="handleRemove2"
                        :file-list="fileList2"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                    </el-upload>
                </el-form-item>
            </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleFile = false">取 消</el-button>
            <el-button type="primary" @click="submitUpload ">上传</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="附件下载"
        :visible.sync="dialogVisibleFile2"
        width="30%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        :close-on-click-modal=false>
        <el-table
            :data="fileData"
            border
            style="width: 100%">
            <el-table-column
            prop="fileType"
            label="文件类型"
            width="180">
            </el-table-column>
            <el-table-column
            prop="name"
            label="文件下载"
            >
            <template v-slot="scope">
                <span class="upload" @click='uploadFile(scope.row.filePath,scope.row.fileName)'>{{scope.row.fileName}}</span>
            </template>
            </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleFile2 = false">关 闭</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
  import processPlanAdd from '../actChildCom/processPlanAdd'
  import proLeft from './processLeft'
  export default {
      name: "prodashiji",
      data(){
          return{
              tableData:[],
              dialogVisible:false,
              dialogVisibleFile:false,
              dialogVisibleFile2:false,
              isAdd:true,
              someData:{
              },
              fileList:[],
              fileList2:[],
              fileid:'',
              fileData:[]
          }
      },
      created(){
          this.getProApp()
      },
      methods:{
          async getProApp(){
              const {data:res}= await this.$http6.get('/v1.0/schedule/planningScheduleReviewReview/findAll?projectId='+this.$root.proId)
              if(res.businessCode!='3000'){
                  return this.$message.error('查询失败！'+res.message)
              }
              this.tableData=res.data;
          },
          async addproApp(){
            let childData2 = this.$refs.childProAppRef.addFrom;
            console.log(childData2)
            const {data:res}= await this.$http6.post('/v1.0/schedule/planningScheduleReviewReview/save',this.$refs.childProAppRef.addFrom)
             console.log(res);
             if(res.businessCode!='4000'){
                  return this.$message.error('新增进度计划审批表失败！'+res.message)
             }
             this.$message.success('新增进度计划审批表成功！')
             this.dialogVisible=false
             this.getProApp()
          },
          async getInfo(id){
             const {data:res}= await this.$http6.get('/v1.0/schedule/planningScheduleReviewReview/findById?id='+id)
              if(res.businessCode!='3000'){
                  return this.$message.error('查询失败！'+res.message)
              }
              this.isAdd=false
              this.someData=res.data;
              this.dialogVisible=true
          },
          async faqi(id){
            let assi=window.sessionStorage.getItem('userid')+'_'+window.sessionStorage.getItem('name')+'_'+window.sessionStorage.getItem('organizationName')
            const {data:res}= await this.$http2.post('/InitiateProcess/start/InitiatingProcess',{
              assignee:assi,
              businessCode:'PROJECT_MANAGER_APPOINTMENT',
              instanceIds:[id],
              "universalProcess": true
            })
            if(res.businessCode!='4000'){
                return this.$message.error('发起失败！'+res.message)
            }
            this.$message.success('发起成功！')
          },
          upFile(id){
             this.fileid=id
             this.dialogVisibleFile=true
          },
          filechange(file,List){
            this.fileList=List
          },
          filechange2(file,List){
            this.fileList2=List
          },
          handleRemove(file, List) {
                this.fileList=List
          },
          handleRemove2(file, List) {
                this.fileList2=List
          },
          async getFiles(id){
            const {data:res}= await this.$http6.get('/v1.0/project/planningScheduleReview/findAllFileByPlanningScheduleReviewId?planningScheduleReviewId='+id)
              if(res.businessCode!='3000'){
                  return this.$message.error('查询失败！'+res.message)
              }
              this.fileData=res.data
              this.dialogVisibleFile2=true
          },
          async submitUpload(){
                console.log(this.fileList)
                var formData = new FormData();
                for(let i in this.fileList){
                    formData.append("multipartFiles", this.fileList[i].raw);
                }
                formData.append("projectManagerAppointmentId", this.fileid)
                formData.append("fileType", '进度计划')
                const {data:res}= await this.$http6.post('/v1.0/project/planningScheduleReview/savePlanningScheduleReview',formData)
                if(res.businessCode!='1000'){
                    return this.$message.error('上传进度计划失败！'+res.message)
                }
                this.$message.success('上传进度计划成功！')
                var formData2 = new FormData();
                for(let i in this.fileList2){
                    formData2.append("multipartFiles", this.fileList2[i].raw);
                }
                formData2.append("projectManagerAppointmentId", this.fileid)
                formData2.append("fileType", '进度计划评审表')
                const {data:res2}= await this.$http6.post('/v1.0/project/planningScheduleReview/savePlanningScheduleReview',formData2)
                if(res2.businessCode!='1000'){
                    return this.$message.error('上传进度计划评审表失败！'+res2.message)
                }
                this.$message.success('上传进度计划评审表成功！')
                this.dialogVisibleFile=false
           },
          async uploadFile(path,name){
            const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
            if(res){
                var elink = document.createElement('a');
                    elink.download = name;
                    elink.style.display = 'none';
                    var blob = new Blob([res]);
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
            },
          async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目经理任命, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/projectManagerAppointment/delete/'+id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
          },
          handleClose(done) {
              this.$confirm('确认关闭？')
                  .then(_ => {
                      done();
                  })
                  .catch(_ => {});
          },
          dialogClose(){
              this.$refs.addFormRef.resetFields();
          },
          editdialogClose(){
              this.$refs.editFormRef.resetFields();
          }
      },
      components:{
        processPlanAdd,
          proLeft
      }
  }
</script>

<style scoped lang="less">
  .serverTable{
      position: absolute;
      width: 97%;
      padding: 0%;
      /*height: 100%;*/
      height: calc(100% - 140px);
  }

</style>
