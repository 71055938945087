<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">新增进度计量报表</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            show-summary
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column 
                                type="index"
                                label="序号"
                                width="50"
                        >
                        </el-table-column>
                        <el-table-column prop="measurementProgress"  label="工程进度计量（元）"  width="200"
                        >  
                        </el-table-column>
                        <el-table-column prop="progressTime"  label="日期时间"  width="220" >  
                        </el-table-column>
                        <el-table-column prop="remarks"  label="备注"  width="250" >  
                        </el-table-column>
                        <el-table-column prop="attacheIds"  label="附件"  width="320" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.files[0].filePath)"> {{scope.row.files[0].fileName}}</a>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="downFile(scope.row.files[0].filePath,scope.row.files[0].fileName)"  size="mini">下载</el-button>
                                <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
   
        <el-dialog :visible.sync="writeForm" title="附件上传" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" >
                <el-form-item label="工程进度计量（元）" prop="measurementProgress">
                    <el-input  v-model="addForm.measurementProgress"></el-input>
                </el-form-item>
                <el-form-item label="进度时间" prop="progressTime">
                    <el-date-picker
                        v-model="addForm.progressTime"
                        align="right"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input  v-model="addForm.remarks"></el-input>
                </el-form-item>
                <el-form-item label="附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-progress="handleProgress"
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                     </el-upload>
                     <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                loadbut:false,
                loadbutext:"保 存",
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                fileList:[],
                menuId:window.sessionStorage.getItem('menuId'),
                addForm:{
                    measurementProgress:'',
                    progressTime:'',
                    remarks:'',
                    projectId:this.$root.proId,
                    attacheIds:[]
                },
                progress: 0,
                percentage:0,
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
                }
            }
        },
        created(){
            this.getLeftMenu()
            this.getProApp()
            const load = this.$loading({
            lock:true,
            text:'Loading',
            spinner:'el-icon-loading',
            background:'rgba(0,0,0,0.7)'
            });
            load.close();
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-progress/findAllByProjectId?projectId='+this.$root.proId)
              this.tableData=res.data
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=64')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-progress/delete/'+row.id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
             async yulan(path){
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async downFile(path,name){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(){
                this.fileList=[]
                this.writeForm=true
            },
            handleProgress(event, file, fileList) {

            },
           async uploadModel(){
            this.loadbut = true
            this.loadbutext = "提交中..."
            var formData = new FormData();
            for(let i in this.fileList){
                formData.append("files", this.fileList[i].raw);
            }
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(this.percentage)
                    }
                    }
                };
            const { data: res2 } = await this.$http1.post('/v1.0/enterprises/project-file/upload/AttacheInfoBatch', formData,config)
            if(res2.businessCode==1000){
            this.addForm.attacheIds=[]
            for(let j in res2.data){
                this.addForm.attacheIds.push(res2.data[j].attacheId)
            }
            const { data: res } = await this.$http1.post('/v1.0/enterprises/project-progress/save', this.addForm)
            if(res.businessCode==4000){
                this.$message.success("提交成功");
                this.getProApp()
                this.percentage = 0
                this.loadbut = false
                this.loadbutext = "保 存"
                this.writeForm = false;
            }else{
              this.$message.error("提交失败！");
            }
           }else{
                this.addForm.attacheIds=[]
                const { data: res } = await this.$http1.post('/v1.0/enterprises/project-progress/save', this.addForm)
                if(res.businessCode==4000){
                    this.$message.success("提交成功");
                    this.getProApp()
                    this.writeForm = false;
                }else{
                this.$message.error("提交失败！");
                }
            }
          }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
  </style>
  