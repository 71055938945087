<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">新增分包合同表</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column prop="contractorMonetary"  label="合同金额"  width="100">  
                        </el-table-column>
                        <el-table-column prop="contractorName"  label="合同名称"  width="200" >  
                        </el-table-column>
                        <el-table-column prop="obligations"  label="甲方单位"  width="150" >  
                        </el-table-column>
                        <el-table-column prop="secondParty"  label="乙方单位"  width="150" >  
                        </el-table-column>
                        <el-table-column prop="signatureTime"  label="签订时间"  width="150" >  
                        </el-table-column>
                        <el-table-column prop="attacheIds"  label="附件"  width="220" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.files[0].filePath)" v-if="scope.row.files.length>0"> {{scope.row.files[0].fileName}}</a>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="xiangqing(scope.row)"  size="mini">详情</el-button>
                                <el-button  type="primary" @click="downFile(scope.row.files[0].filePath,scope.row.files[0].fileNeme)"  size="mini">下载</el-button>
                                <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
   
        <el-dialog :visible.sync="writeForm" title="新增合同" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="140px" :inline="true">
                <el-form-item label="合同金额(元)" prop="contractorMonetary">
                    <el-input  v-model="addForm.contractorMonetary"></el-input>
                </el-form-item>
                <el-form-item label="合同名称" prop="contractorName">
                    <el-input  v-model="addForm.contractorName"></el-input>
                </el-form-item>
                <el-form-item label="合同序号" prop="contractorSerial">
                    <el-input  v-model="addForm.contractorSerial"></el-input>
                </el-form-item>
                <el-form-item label="项目工期" prop="projectDuration">
                    <el-input  v-model="addForm.projectDuration"></el-input>
                </el-form-item>
                <el-form-item label="签订时间" prop="signatureTime">
                    <el-date-picker
                        v-model="addForm.signatureTime"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="甲方单位" prop="obligations">
                    <el-input  v-model="addForm.obligations"></el-input>
                </el-form-item>
                <el-form-item label="甲方单位联系人" prop="obligationsLiaison">
                    <el-input  v-model="addForm.obligationsLiaison"></el-input>
                </el-form-item>
                <el-form-item label="乙方单位" prop="secondParty">
                    <el-input  v-model="addForm.secondParty"></el-input>
                </el-form-item>
                <el-form-item label="乙方单位联系人" prop="secondPartyLiaison">
                    <el-input  v-model="addForm.secondPartyLiaison"></el-input>
                </el-form-item>
                <el-form-item label="附件" prop="attacheIds">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                     </el-upload>
                     <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>
        <el-dialog :visible.sync="writeForm2" title="查看详情" width="50%" >
            <el-form ref="addFormRef" :model="editform" label-width="140px" inline="true">
                <el-form-item label="合同金额" prop="contractorMonetary">
                    <el-input  v-model="editform.contractorMonetary"></el-input>
                </el-form-item>
                <el-form-item label="合同名称" prop="contractorName">
                    <el-input  v-model="editform.contractorName"></el-input>
                </el-form-item>
                <el-form-item label="合同序号" prop="contractorSerial">
                    <el-input  v-model="editform.contractorSerial"></el-input>
                </el-form-item>
                <el-form-item label="项目工期" prop="projectDuration">
                    <el-input  v-model="editform.projectDuration"></el-input>
                </el-form-item>
                <el-form-item label="签订时间" prop="signatureTime">
                    <el-date-picker
                        v-model="editform.signatureTime"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="甲方单位" prop="obligations">
                    <el-input  v-model="editform.obligations"></el-input>
                </el-form-item>
                <el-form-item label="甲方单位联系人" prop="obligationsLiaison">
                    <el-input  v-model="editform.obligationsLiaison"></el-input>
                </el-form-item>
                <el-form-item label="乙方单位" prop="secondParty">
                    <el-input  v-model="editform.secondParty"></el-input>
                </el-form-item>
                <el-form-item label="乙方单位联系人" prop="secondPartyLiaison">
                    <el-input  v-model="editform.secondPartyLiaison"></el-input>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="xiugaiModel">保 存</el-button>
                                  <el-button @click="writeForm2 = false">关 闭</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                loadbut:false,
                loadbutext:"保 存",
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                writeForm2:false,
                fileList:[],
                progress: 0,
                percentage:0,
                menuId:window.sessionStorage.getItem('menuId'),
                editform:{

                },
                addForm:{
                    contractorMonetary:'',
                    contractorName:'',
                    contractorSerial:'',
                    projectDuration:'',
                    signatureTime:'',
                    obligations:'',
                    obligationsLiaison:'',
                    secondParty:'',
                    secondPartyLiaison:'',
                    cooperativeUnit:'',
                    cooperativeLiaison:'',
                    projectId:this.$root.proId,
                    attacheIds:[]
                },
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
                }
            }
        },
        created(){
            this.getLeftMenu()
            this.getProApp()
            const load = this.$loading({
            lock:true,
            text:'Loading',
            spinner:'el-icon-loading',
            background:'rgba(0,0,0,0.7)'
            });
            load.close();
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-sub-contractor/findAllByProjectId?projectId='+this.$root.proId)
              this.tableData=res.data
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=56')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            xiangqing(row){
                this.editform=row
                this.writeForm2=true
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-sub-contractor/delete/'+row.id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
            async yulan(path){
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async downFile(path,name){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(){
                this.fileList=[]
                this.writeForm=true
            },
           async uploadModel(){
            this.loadbut = true
            this.loadbutext = "提交中..."
            var formData = new FormData();
            for(let i in this.fileList){
                formData.append("files", this.fileList[i].raw);
            }
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(this.percentage)
                    }
                    }
                };
            const { data: res2 } = await this.$http1.post('/v1.0/enterprises/project-file/upload/AttacheInfoBatch', formData,config)
            if(res2.businessCode==1000){
            this.addForm.attacheIds=[]
            for(let j in res2.data){
                this.addForm.attacheIds.push(res2.data[j].attacheId)
            }
            const { data: res } = await this.$http1.post('/v1.0/enterprises/project-sub-contractor/save', this.addForm)
            if(res.businessCode==4000){
                this.$message.success("提交成功");
                this.getProApp()
                this.percentage = 0
                this.loadbut = false
                this.loadbutext = "保 存"
                this.writeForm = false;
            }else{
              this.$message.error("提交失败！");
            }
           }
          },
          async xiugaiModel(){
           
            this.editform.attacheId=[]
            for(let i in this.editform.files){
                this.editform.attacheId.push(this.editform.files[i].attacheId)
            }
            delete this.editform.files;
            console.log(this.editform)
            const { data: res } = await this.$http1.put('/v1.0/enterprises/project-sub-contractor/update', this.editform)
            if(res.businessCode==4000){
                this.$message.success("提交成功");
                this.getProApp()
                this.writeForm2 = false;
            }else{
              this.$message.error("提交失败！");
            }
          },
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
  </style>
  