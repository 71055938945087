<template>
    <div class="bg">
        <div id="quality">
            <div class="bim3d" id="bimshow">
                <el-button type="goon" round @click="showyx" class="yingxiang" style="" v-show="isfull" size="small">{{yxtitle}}</el-button>
                <el-button type="goon" round @click="showyx" class="yingxiangfull" style="" v-show="!isfull" size="small">{{yxtitle}}</el-button>
                <el-button type="goon" round @click="full3d" class="full" style="" v-show="isfull" size="small">全屏</el-button>
                <el-button type="goon" round @click="suoxiao3d" class="suoxiao" style=""  v-show="!isfull" size="small">缩小</el-button>
            </div>
            <div class="cad3d" id="map">
                <div class="cadheader" v-has="'quatongji'">
                    <div>
                        <p>{{total}}</p>
                        <span>质量检查总数</span>
                    </div>
                    <div>
                        <p>{{qualifiedCount}}</p>
                        <span>质检合格总数</span>
                    </div>
                    <div>
                        <p>{{notQualifiedCount}}</p>
                        <span>质检未合格总数</span>
                    </div>
                </div>
                <div id="createUserNum">
                      <createEcharts/>
                </div>
                <div id="updateUserNum">
                       <updateEcharts/>
                </div>
            </div>
            <div class="qtable">
               <el-card class="box-card" >
                    <div slot="header" class="clearfix" >
                        <el-button class="addzpzb"  icon="el-icon-circle-plus-outline"  @click="dialogVisible = true" v-has="'addqua'" >新增质评质保</el-button>
                        <el-button class="addzpzb"  icon="el-icon-circle-plus-outline"  @click="addQualityPlan"  >发起质量计划流程</el-button>
                    </div>
                    <div class="serverTable">
                    <el-tabs v-model="activeName"   type="border-card">
                          <el-tab-pane label="质评质保" name="first">   
                           <el-table
                                :data="tableData"
                                height="100%"
                                border
                                :header-cell-style="{background:'#e3ecfb',color:'black'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="description"
                                    label="质评质保描述"
                                    width="150"
                            >
                             <template v-slot="scope">
                                    <a style="text-decoration: underline;cursor: pointer"  target="_blank" @click="ShowBIMFile(scope.row.id)">{{scope.row.description}}</a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="files"
                                    label="质评资料"
                                    width="160"
                            >
                                <template v-slot="scope">
                                    <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files1" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="files"
                                    label="质保资料"
                                    width="160"
                            >
                                <template v-slot="scope">
                                    <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files2" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="files"
                                    label="报验单资料"
                                    width="160"
                            >
                                <template v-slot="scope">
                                    <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files3" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="files"
                                    label="材料设备监测资料"
                                    width="160"
                            >
                                <template v-slot="scope">
                                    <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files4" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="files"
                                    label="其它资料"
                                    width="160"
                            >
                                <template v-slot="scope">
                                    <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files5" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="evaluationResult"
                                    label="评定结果"
                                    align="center"
                                    width="80"
                            >
                                <template v-slot="scope">
                                    <span v-if="scope.row.evaluationResult==1" style="color: green">合格</span>
                                    <span v-else-if="scope.row.evaluationResult==0" style="color: red;">不合格</span>
                                    <span v-else style="color: blue;">未评定</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="evaluationUserName"
                                    label="评定人"
                                    align="center"
                                    width="70"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="evaluationDate"
                                    label="评定日期"
                                    align="center"
                                    width="110"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="createUserName"
                                    label="创建人"
                                    align="center"
                                    width="70"
                            >
                            </el-table-column>
                           <!-- <el-table-column
                                    prop="createDate"
                                    label="创建时间"
                                    align="center"
                                    width="90"
                            >
                            </el-table-column>-->
                            <el-table-column
                                    label="操作"
                                    align="center"
                            >
                                <template v-slot="scope">
                                    <el-button  type="primary" @click="hege(scope.row.id)" size="mini" v-has="'hege'" >合格评定</el-button>
                                    <el-button  type="primary" @click="guanlian(scope.row.id)" size="mini" >关联模型</el-button>
                                      <el-button  type="primary" @click="editBim(scope.row.id)" size="mini" >修改模型</el-button>
                                    <el-button  type="primary" @click="editDialog(scope.row.id)"  size="mini" v-has="'editqua'" >修改</el-button>
                                    <el-button  type="primary" @click="deleteMsg(scope.row.id)"  size="mini" v-has="'delqua'" >删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                            </el-tab-pane>
                    <el-tab-pane label="质量计划流程" name="second">
                                    <el-table
                                    :data="tableData2"
                                    height="100%"
                                    border
                                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
                                    >
                                <el-table-column
                                        type="index"
                                        label="序号"
                                        width="50"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="section"
                                        label="阶段"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="moderator"
                                        label="主持人"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="conferenceVenue"
                                        label="会议地点"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="appointmentDate"
                                        label="日期"
                                        width="180"
                                >
                                <template #default="scope">
                                    <div style="display: flex; align-items: center">
                                        <el-icon>
                                            <timer />
                                        </el-icon>
                                        <span style="margin-left: 10px">{{
                                            times.convertUTCTimeToLocalTime(scope.row.appointmentDate)
                                        }}</span>
                                    </div>
                                </template>
                                </el-table-column>
                                <el-table-column
                                        prop="inChargeOpinion"
                                        label="评审内容"
                                        width="200"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="creatorName"
                                        label="评审意见"
                                        align="center"
                                        width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="createdTime"
                                        label="创建时间"
                                        align="center"
                                        width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="verifyStatus"
                                        label="流程状态"
                                        align="center"
                                        width="100"
                                >
                                    <template v-slot="scope">
                                        <span v-if="scope.row.verifyStatus==null||scope.row.verifyStatus==0">制单</span>
                            <span v-else-if="scope.row.verifyStatus==1">提交审批</span>
                            <span v-else-if="scope.row.verifyStatus==2">审批通过</span>
                            <span v-else-if="scope.row.verifyStatus==3">审批不通过</span>
                            <span v-else-if="scope.row.verifyStatus==4">上报不审批</span>
                            <span v-else-if="scope.row.verifyStatus==-1">未填报</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="操作"
                                        align="center"
                                >
                                    <template v-slot="scope">
                                        <el-button  type="primary" @click="getInfo(scope.row.id)" icon="el-icon-edit" size="mini">详情</el-button>
                                        <el-button  type="primary" @click="faqi(scope.row.id)" icon="el-icon-edit" size="mini" v-has="'editevent'">发起</el-button>
                                        <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delevent'">删除</el-button>
                                        <el-button  type="primary" @click="upFile(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delevent'">附件上传</el-button>
                              <el-button  type="primary" @click="getFiles(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delevent'">附件下载</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            </el-tab-pane>
                    </el-tabs>
                     </div>
                </el-card>
            </div>
        </div>
        <el-dialog
              title="项目质量计划审批表"
              :visible.sync="dialogVisibleAdd"
              width="42%"
              center
              :close-on-click-modal="false"
      >
        <qualityPlanAdd ref="childProAppRef" :someData="someData" :isAdd="isAdd"></qualityPlanAdd>
            <span slot="footer" class="dialog-footer" v-show="isAdd">
                <el-button round type="goon" size="medium" @click="dialogVisibleAdd = false">取 消</el-button>
                <el-button round type="goon" size="medium"  @click="addproApp" >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="添加质评质保"
                :visible.sync="dialogVisible"
                width="30%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="140px" :rules="addFormRule">
                <el-form-item label="质评质保描述" prop="description">
                            <el-input v-model="addForm.description" type="textarea"
                                      :rows="3" ></el-input>
                 </el-form-item>
                <el-form-item label="质评资料">
                            <el-upload
                                    class="upload-demo"
                                    ref="upload"
                                    action="#"
                                    :on-remove="handleRemove"
                                    :on-change="filechange"
                                    :file-list="fileList"
                                    :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                            </el-upload>
                </el-form-item>
                <el-form-item label="质保资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove1"
                            :on-change="filechange1"
                            :file-list="fileList1"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="报验单资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove2"
                            :on-change="filechange2"
                            :file-list="fileList2"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="材料设备监测资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove3"
                            :on-change="filechange3"
                            :file-list="fileList3"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="其它资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove4"
                            :on-change="filechange4"
                            :file-list="fileList4"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addQuality" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改质评质保"
                :visible.sync="editdialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="120px"  :rules="addFormRule">
                <el-form-item label="质评质保描述" prop="description">
                    <el-input v-model="editForm.description" type="textarea"
                              :rows="3" ></el-input>
                </el-form-item>
                <el-form-item label="质评资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit"
                            :on-change="filechangeEdit"
                            :file-list="fileListEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="质保资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit1"
                            :on-change="filechangeEdit1"
                            :file-list="fileListEdit1"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="报验单资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit2"
                            :on-change="filechangeEdit2"
                            :file-list="fileListEdit2"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="材料设备监测资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit3"
                            :on-change="filechangeEdit3"
                            :file-list="fileListEdit3"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="其它资料">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit4"
                            :on-change="filechangeEdit4"
                            :file-list="fileListEdit4"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="EditQuality" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="关联BIM模型"
                :visible.sync="dialogVisibleBim"
                width="30%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef"  label-width="140px" >
                <el-form-item label="BIM模型">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveBim"
                            :on-change="Bimchange"
                            :file-list="BimList"
                            accept=".zip"
                            :limit="1"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="上传说明">
                    <span style="color: red"> 上传BIM模型为3dtiles格式的ZIP压缩包，可通过下载CesiumLab插件导入到revit等建模软件中进行转换。</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleBim = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="guanlianBim" :disabled="isDisable">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
        title="附件上传"
        :visible.sync="dialogVisibleFile"
        width="30%"
        :close-on-click-modal=false>
            <el-form ref="addFormRef"  label-width="140px" >
                <el-form-item label="项目质量计划">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :on-change="filechange"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="项目质量计划评审表">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :on-change="filechange2"
                        :on-remove="handleRemove2"
                        :file-list="fileList2"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleFile = false">取 消</el-button>
            <el-button type="primary" @click="submitUpload ">上传</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="附件下载"
        :visible.sync="dialogVisibleFile2"
        width="30%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        :close-on-click-modal=false>
        <el-table
            :data="fileData"
            border
            style="width: 100%">
            <el-table-column
            prop="fileType"
            label="文件类型"
            width="180">
            </el-table-column>
            <el-table-column
            prop="name"
            label="文件下载"
            >
            <template v-slot="scope">
                <span class="upload" @click='uploadFile(scope.row.filePath,scope.row.fileName)'>{{scope.row.fileName}}</span>
            </template>
            </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleFile2 = false">关 闭</el-button>
        </span>
      </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
    </div>
</template>

<script>
    import qualityPlanAdd from '../actChildCom/qualityPlanAdd'
    import "../../assets/css/ol1.css";
    import "../../assets/css/ol2.css";
    import Map from 'ol/Map';
    import View from 'ol/View';
    import * as control from 'ol/control';
    import Projection from 'ol/proj/Projection';
    import {TileSuperMapRest,MapService} from  '@supermap/iclient-ol';
    import TileLayer from 'ol/layer/Tile'
    import createEcharts from "../echarts/QualityCreateNum";
    import updateEcharts from "../echarts/QualityUpdateNum";
    var map;
    var extent = [ -23637.66, -29611.06, 58751.37, 36793.78];
    var projection = new Projection({
        code:'',
        extent: extent,
        units: 'm',
        getPointResolution:function(resolution, point){
            return resolution
        }
    });
    var viewer,tileset;
    export default {
        name: "quality",
        data(){
            return{
                 dialogVisibleAdd:false,
                 isAdd:true,
                 someData:{
                
                 },
                tableData2:[],
                activeName:'first',
                tableData:[],
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                proshow:false,
                data: [],
                dialogVisible:false,
                editdialogVisible:false,
                dialogVisibleBim:false,
                BimId:'',
                editBimid:'',
                isfull:true,
                yxtitle:'显示影像',
                isEdit:0,
                addForm:{
                    description:'',
                    projectId:this.$root.proId,
                },
                addFormRule:{
                    description: [{required: true, message: '请输入问题详情', trigger: 'blur'}],
                },
                isDisable:false,
                editForm:{},
                BimList:[],
                fileList:[],
                fileList1:[],
                fileList2:[],
                fileList3:[],
                fileList4:[],
                fileListEdit:[],
                fileListEdit1:[],
                fileListEdit2:[],
                fileListEdit3:[],
                fileListEdit4:[],
                fileListEditDeleteID:[],
                fileListEditDeleteID1:[],
                fileListEditDeleteID2:[],
                fileListEditDeleteID3:[],
                fileListEditDeleteID4:[],
                fileListEditAdd:[],
                fileListEditAdd1:[],
                fileListEditAdd2:[],
                fileListEditAdd3:[],
                fileListEditAdd4:[],
                total:0,
                qualifiedCount:0,
                notQualifiedCount:0,
                pageInfo:{
                    audits:null,
                    ownerName:'',
                    pageNum:1,
                    pageSize:99,
                    projectName:''
                },
                dialogVisibleFile:false,
                dialogVisibleFile2:false,
                fileList:[],
                fileList2:[],
                fileid:'',
                fileData:[]
            }
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http7.post('/v1.0/quality/quality-program/findPages',this.pageInfo)
              console.log(res)
              this.tableData2=res.list;
             console.log(this.tableData2)
           },
            addQualityPlan(){
                this.dialogVisibleAdd = true;
                this.isAdd=true;
                this.someData={}
            },
            async addproApp(){
            let childData = this.$refs.childProAppRef.addFrom;
            console.log(childData)
            const {data:res}= await this.$http7.post('/v1.0/quality/quality-program/insert/Quality',childData)
             if(res.businessCode!='4000'){
                  return this.$message.error('项目质量计划报存失败！'+res.message)
             }
             this.$message.success('项目质量计划保存成功！')
             this.dialogVisibleAdd=false
             this.getProApp()
             this.activeName='second'
           },
           async getInfo(id){
             const {data:res}= await this.$http7.get('/v1.0/quality/quality-program/query/QualityById?id='+id)
              if(res.businessCode!='3000'){
                  return this.$message.error('查询失败！'+res.message)
              }
              this.isAdd=false
              this.someData=res.data;
              this.dialogVisibleAdd=true
          },
           async faqi(id){
            let assi=window.sessionStorage.getItem('userid')+'_'+window.sessionStorage.getItem('name')+'_'+window.sessionStorage.getItem('organizationName')
            const {data:res}= await this.$http2.post('/InitiateProcess/start/InitiatingProcess',{
              assignee:assi,
              businessCode:'PROJECT_MANAGER_APPOINTMENT',
              instanceIds:[id],
              "universalProcess": true
            })
            if(res.businessCode!='3000'){
                return this.$message.error('发起失败！'+res.message)
            }
          },
           upFile(id){
             this.fileid=id
             this.dialogVisibleFile=true
          },
          filechange(file,List){
            this.fileList=List
          },
          filechange2(file,List){
            this.fileList2=List
          },
          handleRemove(file, List) {
                this.fileList=List
          },
          handleRemove2(file, List) {
                this.fileList2=List
          },
          async getFiles(id){
            const {data:res}= await this.$http1.get('/v1.0/project/projectDepartmentEstablish/findAllFileByProjectDepartmentEstablishId?projectDepartmentEstablishId='+id)
              if(res.businessCode!='4000'){
                  return this.$message.error('查询失败！'+res.message)
              }
              this.fileData=res.data
              this.dialogVisibleFile2=true
          },
          async submitUpload(){
                console.log(this.fileList)
                var formData = new FormData();
                for(let i in this.fileList){
                    formData.append("multipartFiles", this.fileList[i].raw);
                }
                formData.append("projectManagerAppointmentId", this.fileid)
                formData.append("fileType", '项目部组建方案')
                const {data:res}= await this.$http1.post('/v1.0/project/projectManagerAppointment/saveProjectManagerAppointment',formData)
                if(res.businessCode!='1000'){
                    return this.$message.error('上传项目部组建方案失败！'+res.message)
                }
                this.$message.success('上传项目部组建方案成功！')
                var formData2 = new FormData();
                for(let i in this.fileList2){
                    formData2.append("multipartFiles", this.fileList2[i].raw);
                }
                formData2.append("projectManagerAppointmentId", this.fileid)
                formData2.append("fileType", '项目部组建方案评审表')
                const {data:res2}= await this.$http1.post('/v1.0/project/projectManagerAppointment/saveProjectManagerAppointment',formData2)
                if(res2.businessCode!='1000'){
                    return this.$message.error('上传项目部组建方案评审表失败！'+res2.message)
                }
                this.$message.success('上传项目部组建方案评审表成功！')
                this.dialogVisibleFile=false
           },
          async uploadFile(path,name){
            const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
            if(res){
                var elink = document.createElement('a');
                    elink.download = name;
                    elink.style.display = 'none';
                    var blob = new Blob([res]);
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
            },
            async getAllnum(){
                const {data:res}=await  this.$http7.get('/v1.0/quality/statistics/queryInspectionCountStatistics?projectId='+this.$root.proId);
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }

            },
            async getIshege(){
                const {data:res}=await  this.$http7.get('/v1.0/quality/statistics/queryEvaluationCountStatistics?projectId='+this.$root.proId);
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                this.qualifiedCount=res.data.qualifiedCount;
                this.notQualifiedCount=res.data.notQualifiedCount
            },
            addmap(){
                new MapService("http://localhost:8090/iserver/services/map-cad/rest/maps/lou").getMapInfo(function (serviceResult) {
                    var mapJSONObj = serviceResult.result;
                     map = new Map({
                        target: 'map',
                         controls: control.defaults({
                             attribution: false
                         }),
                        view: new View({
                            center: [17556.85 , 3591.36 ],
                            zoom: 1,
                            projection: projection,
                            origin: [48.4, -55.58],
                            multiWorld: true
                        })
                    });
                    var layer = new TileLayer({
                        source: new TileSuperMapRest(TileSuperMapRest.optionsFromMapJSON("http://localhost:8090/iserver/services/map-cad/rest/maps/lou ", mapJSONObj))
                    });
                    map.addLayer(layer);
                })
            },
            showBim(){
                var obj=[6378137.0, 6378137.0,6356752.3142451793];
              //  Cesium.Ellipsoid.WGS84=Object.freeze(new Cesium.Ellipsoid(obj[0],obj[1],obj[2]))
               Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYWVkYjZkZS1hNDg5LTQ1MGQtOThlMy02OTJkYmQwOWFiODQiLCJpZCI6NDU4NjAsImlhdCI6MTYxNTUxOTE1MH0.KUGe0o74LmuTSygie8IFrClVKYTtaMoiauHCywm7aG0';

                viewer = new Cesium.Viewer("bimshow",{
                    // infoBox : false,
                    // selectionIndicator : false,
                    geocoder: false,
                    homeButton: false,
                    sceneModePicker: false,
                    baseLayerPicker: false,
                    navigationHelpButton: false,
                    animation: false,
                    // creditContainer:"credit",
                    timeline: false,
                    fullscreenButton: false,
                    vrButton: false,
                });
                viewer.imageryLayers.get(0).show = false;
                viewer._cesiumWidget._creditContainer.style.display = "none";
                viewer.scene.globe.depthTestAgainstTerrain = false
                 tileset=viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
                     //   url:'http://localhost:8086/sanwei/3dtiles/tileset.json'
                       url:'http://epc.xjbtgis.cn/sanwei/duoziduan/tileset.json'
                }))
                tileset.readyPromise.then(function (tileset) {
                    console.log(tileset)
                    var boundingSphere=tileset.boundingSphere;
                    viewer.camera.viewBoundingSphere(boundingSphere,new Cesium.HeadingPitchRange(0.0,-0.5,boundingSphere.radius))
                    viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
                    /*viewer.zoomTo(
                        tileset,
                        new Cesium.HeadingPitchRange(
                            0.5,
                            -0.2,
                            tileset.boundingSphere.radius * 4.0
                        )
                    );*/
                }).otherwise(function (err) {
                    throw(err)
                })


            },
            showyx(){
                if(this.yxtitle=='显示影像'){
                    viewer.imageryLayers.get(0).show = true;
                    this.yxtitle='隐藏影像'
                }else {
                    viewer.imageryLayers.get(0).show = false;
                    this.yxtitle='显示影像'
                }

            },

            full3d(){
                Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYWVkYjZkZS1hNDg5LTQ1MGQtOThlMy02OTJkYmQwOWFiODQiLCJpZCI6NDU4NjAsImlhdCI6MTYxNTUxOTE1MH0.KUGe0o74LmuTSygie8IFrClVKYTtaMoiauHCywm7aG0';
                document.getElementById('bimshow').style.position = 'absolute'
                document.getElementById('bimshow').style.width = 1680+'px'
                document.getElementById('bimshow').style.height = 834+'px'
                this.isfull=false
            },
            suoxiao3d(){
                document.getElementById('bimshow').style.position = ''
                document.getElementById('bimshow').style.width = 830+'px'
                document.getElementById('bimshow').style.height = 420+'px'
                this.isfull=true
            },
            async getQuality(){
                const {data:res}= await this.$http7.get('/v1.0/quality/evaluation/findAll?projectId='+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                this.total=res.data.length;
                this.tableData=res.data;
                var files1=[],files2=[],files3=[],files4=[],files5=[]
                for(let i in this.tableData){
                    files1=[],files2=[],files3=[],files4=[],files5=[]
                    for(let j in this.tableData[i].files){
                        if(this.tableData[i].files[j].evaluationType==1){
                            files1.push(this.tableData[i].files[j])
                        }else   if(this.tableData[i].files[j].evaluationType==2){
                            files2.push(this.tableData[i].files[j])
                        }else   if(this.tableData[i].files[j].evaluationType==3){
                            files3.push(this.tableData[i].files[j])
                        }else   if(this.tableData[i].files[j].evaluationType==4){
                            files4.push(this.tableData[i].files[j])
                        }else {
                            files5.push(this.tableData[i].files[j])
                        }
                    }
                    this.tableData[i]['files1']=files1;
                    this.tableData[i]['files2']=files2
                    this.tableData[i]['files3']=files3
                    this.tableData[i]['files4']=files4
                    this.tableData[i]['files5']=files5
                }
            },
            async addQuality(){
                this.$refs.addFormRef.validate(async valid =>{
                    if(!valid) return
                    this.proshow=true
                    var formData=new FormData();
                    for(let x in this.fileList){
                        formData.append("evaluationFiles",this.fileList[x].raw);
                    }
                    for(let x in this.fileList1){
                        formData.append("warrantyFiles",this.fileList1[x].raw);
                    }
                    for(let x in this.fileList2){
                        formData.append("inspectionFiles",this.fileList2[x].raw);
                    }
                    for(let x in this.fileList3){
                        formData.append("checkFiles",this.fileList3[x].raw);
                    }
                    for(let x in this.fileList4){
                        formData.append("otherFiles",this.fileList4[x].raw);
                    }
                    for (let key in this.addForm) {
                        formData.append(key,this.addForm[key]);
                    }
                const {data:res}= await this.$http7.post('/v1.0/quality/evaluation/save', formData)
                if(res.status!='6000'){
                    return this.$message.error('添加质评质保信息失败！'+res.message)
                }
                this.$message.success('添加质评质保信息成功！');
                this.getQuality();
                this.dialogVisible=false;
                this.proshow=false
                })
            },
            async fileupload(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            hege(id){
                 this.$confirm('该项质评质保信息是否合格?', '评定', {
                     confirmButtonText: '合格',
                     cancelButtonText: '不合格',
                     distinguishCancelAndClose: true,
                     customClass: 'message-override',
                     type: 'warning'
                 }).then(async() => {
                     const {data:res}=await  this.$http7.post('/v1.0/quality/evaluation/updateEvaluationResult?id='+id+'&evaluationResult='+1);
                     if(res.status!='6010'){
                         return this.$message.error(res.message)
                     }
                     this.$message.success('评定成功！该项质评质保信息为：合格！')
                     this.getQuality()
                     this.getIshege()
                 }).catch(async(e) => {
                     console.log(e)
                     if(e=='close'){
                         return
                     }
                     const {data:res1}=await  this.$http7.post('/v1.0/quality/evaluation/updateEvaluationResult?id='+id+'&evaluationResult='+0);
                     if(res1.status!='6010'){
                         return this.$message.error(res1.message)
                     }
                     this.$message.success('评定成功！该项质评质保信息为：不合格！')
                     this.getQuality()
                     this.getIshege()
                 });
            },
            async ShowBIMFile(qualityEvaluationId){
                const {data:res}= await this.$http7.get('/v1.0/quality/evaluation/findByQualityEvaluationId?qualityEvaluationId='+qualityEvaluationId)
                if(res.status!=='3000'){
                    return  this.$message.error('查看BIM失败！'+res.message)
                }
                tileset.destroy()
                tileset=viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
                   // url:'http://localhost:8086/sanwei/safulab/tileset.json'
                     url:'http://epc.xjbtgis.cn/sanwei/'+res.data.files[0].filePath+'tileset.json'
                }))
                tileset.readyPromise.then(function (tileset) {
                    console.log(tileset)
                    var boundingSphere=tileset.boundingSphere;
                    viewer.camera.viewBoundingSphere(boundingSphere,new Cesium.HeadingPitchRange(0.0,-0.5,boundingSphere.radius))
                    viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
                    /*viewer.zoomTo(
                        tileset,
                        new Cesium.HeadingPitchRange(
                            0.5,
                            -0.2,
                            tileset.boundingSphere.radius * 4.0
                        )
                    );*/
                }).otherwise(function (err) {
                    throw(err)
                })
            },
            async  guanlian(id){
                 const {data:res}= await this.$http7.get('/v1.0/quality/evaluation/findByQualityEvaluationId?qualityEvaluationId='+id)
                 if(res.status!=='3002'){
                     return  this.$message.error('已经存在BIM模型，若要重新上传，请点击修改模型！')
                 }
                this.isEdit=0
                this.BimId=id;
                this.dialogVisibleBim=true
            },
            async guanlianBim(){
                this.isDisable=true
                this.proshow=true
                console.log(this.BimList)
                if(this.isEdit==0){
                    var formData=new FormData();
                    formData.append("multipartFile",this.BimList[0].raw);
                    formData.append("qualityEvaluationId ",this.BimId);
                    const {data:res}= await this.$http7.post('/v1.0/quality/evaluation/save/bim', formData)
                    if(res.status!='6000'){
                        this.isDisable=false
                        return this.$message.error('关联BIM模型失败！'+res.message)
                    }
                    this.isDisable=false
                    this.proshow=false
                    this.$message.success('关联BIM模型成功！');
                    this.dialogVisibleBim=false
                }else {
                    var formData=new FormData();
                    formData.append("multipartFile",this.BimList[0].raw);
                    formData.append("id",this.editBimid);
                    formData.append("qualityEvaluationId ",this.BimId);
                    const {data:res}= await this.$http7.post('/v1.0/quality/evaluation/update/bim', formData)
                    if(res.status!='6000'){
                        this.isDisable=false
                        return this.$message.error('修改关联BIM模型失败！'+res.message)
                    }
                    this.isDisable=false
                    this.proshow=false
                    this.$message.success('修改关联BIM模型成功！');
                    this.dialogVisibleBim=false
                }

            },
           async editBim(id){
                this.BimId=id;
                const {data:res}= await this.$http7.get('/v1.0/quality/evaluation/findByQualityEvaluationId?qualityEvaluationId='+id)
                if(res.status!=='3000'){
                    return  this.$message.error('不能进行修改！'+res.message)
                }
                this.editBimid=res.data.id;
                this.isEdit=1;
                this.dialogVisibleBim=true
            },
            async editDialog(id){
                const {data:res}=await  this.$http7.get('/v1.0/quality/evaluation/findById?id='+id);
                if(res.status!=="3000"){
                    return this.$message.error(res.message);
                }
                this.editForm=res.data;
                this.fileListEdit=[],this.fileListEdit1=[],this.fileListEdit2=[],this.fileListEdit3=[],this.fileListEdit4=[];
                for(let i in res.data.files){
                    if(res.data.files[i].evaluationType==1){
                        this.fileListEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8810/'+res.data.files[i].filePath})
                    }else if(res.data.files[i].evaluationType==2){
                        this.fileListEdit1.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8810/'+res.data.files[i].filePath})
                    }else if(res.data.files[i].evaluationType==3){
                        this.fileListEdit2.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8810/'+res.data.files[i].filePath})
                    }else if(res.data.files[i].evaluationType==4){
                        this.fileListEdit3.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8810/'+res.data.files[i].filePath})
                    }else if(res.data.files[i].evaluationType==9){
                        this.fileListEdit4.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8810/'+res.data.files[i].filePath})
                    }
                }
                this.fileListEditDeleteID=[],this.fileListEditDeleteID1=[],this.fileListEditDeleteID2=[],this.fileListEditDeleteID3=[],this.fileListEditDeleteID4=[];
                this.fileListEditAdd=[],this.fileListEditAdd1=[],this.fileListEditAdd2=[],this.fileListEditAdd3=[],this.fileListEditAdd4=[]
                this.editdialogVisible=true
            },
            async EditQuality(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    this.proshow=true
                    this.editForm.files=[]
                    const {data:res}= await this.$http7.put('/v1.0/quality/evaluation/update', this.editForm)
                    if(res.status!='6010'){
                        return this.$message.error('修改质评质保失败！'+res.message)
                    }
                    for(let i in this.fileListEditDeleteID){
                        const {data:res2_d}= await this.$http7.delete('/v1.0/quality/file/evaluation/delete/'+this.fileListEditDeleteID[i])
                        if(res2_d.status!='4000'){
                            return this.$message.error('删除质评资料文件失败！'+res2_d.message)
                        }
                    }
                    for(let i in this.fileListEditDeleteID1){
                        const {data:res2_d1}= await this.$http7.delete('/v1.0/quality/file/evaluation/delete/'+this.fileListEditDeleteID1[i])
                        if(res2_d1.status!='4000'){
                            return this.$message.error('删除质保资料文件失败！'+res2_d1.message)
                        }
                    }
                    for(let i in this.fileListEditDeleteID2){
                        const {data:res2_d2}= await this.$http7.delete('/v1.0/quality/file/evaluation/delete/'+this.fileListEditDeleteID2[i])
                        if(res2_d2.status!='4000'){
                            return this.$message.error('删除报验单资料文件失败！'+res2_d2.message)
                        }
                    }
                    for(let i in this.fileListEditDeleteID3){
                        const {data:res2_d3}= await this.$http7.delete('//v1.0/quality/file/evaluation/delete/'+this.fileListEditDeleteID3[i])
                        if(res2_d3.status!='4000'){
                            return this.$message.error('删除材料设备监测资料文件失败！'+res2_d3.message)
                        }
                    }
                    for(let i in this.fileListEditDeleteID4){
                        const {data:res2_d4}= await this.$http7.delete('/v1.0/quality/file/evaluation/delete/'+this.fileListEditDeleteID4[i])
                        if(res2_d4.status!='4000'){
                            return this.$message.error('删除其它资料文件失败！'+res2_d4.message)
                        }
                    }
                        for (let j in this.fileListEditAdd) {
                            var formData2 = new FormData();
                            formData2.append("multipartFile", this.fileListEditAdd[j].raw);
                            formData2.append("qualityEvaluationId", this.editForm.id)
                            formData2.append("evaluationType",1)
                            const {data: res3_a} = await this.$http7.post('/v1.0/quality/file/evaluation/save', formData2)
                            if (res3_a.status != '6000') {
                                return this.$message.error('添加质评资料文件失败！' + res3_a.message)
                            }
                        }
                       for (let j in this.fileListEditAdd1) {
                        var formData2 = new FormData();
                        formData2.append("multipartFile", this.fileListEditAdd1[j].raw);
                        formData2.append("qualityEvaluationId", this.editForm.id)
                           formData2.append("evaluationType",2)
                           const {data: res3_a1} = await this.$http7.post('/v1.0/quality/file/evaluation/save', formData2)
                        if (res3_a1.status != '6000') {
                            return this.$message.error('添加质保资料文件失败！' + res3_a1.message)
                        }
                    }
                    for (let j in this.fileListEditAdd2) {
                        var formData2 = new FormData();
                        formData2.append("multipartFile", this.fileListEditAdd2[j].raw);
                        formData2.append("qualityEvaluationId", this.editForm.id)
                        formData2.append("evaluationType",3)
                        const {data: res3_a2} = await this.$http7.post('/v1.0/quality/file/evaluation/save', formData2)
                        if (res3_a2.status != '6000') {
                            return this.$message.error('添加报验单资料文件失败！' + res3_a2.message)
                        }
                    }
                    for (let j in this.fileListEditAdd3) {
                        var formData2 = new FormData();
                        formData2.append("multipartFile", this.fileListEditAdd3[j].raw);
                        formData2.append("qualityEvaluationId", this.editForm.id)
                        formData2.append("evaluationType",4)
                        const {data: res3_a3} = await this.$http7.post('/v1.0/quality/file/evaluation/save', formData2)
                        if (res3_a3.status != '6000') {
                            return this.$message.error('添加材料设备监测资料文件失败！' + res3_a3.message)
                        }
                    }
                    for (let j in this.fileListEditAdd4) {
                        var formData2 = new FormData();
                        formData2.append("multipartFile", this.fileListEditAdd4[j].raw);
                        formData2.append("qualityEvaluationId", this.editForm.id)
                        formData2.append("evaluationType",9)
                        const {data: res3_a4} = await this.$http7.post('/v1.0/quality/file/evaluation/save', formData2)
                        if (res3_a4.status != '6000') {
                            return this.$message.error('添加其它资料文件失败！' + res3_a4.message)
                        }
                    }
                    this.$message.success('修改质评质保成功！');
                    this.getQuality()
                    this.proshow=false
                    this.editdialogVisible=false;
                })
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该质评质保信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http7.delete('/v1.0/quality/evaluation/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除质评质保信息失败！'+res.message)
                }
                this.$message.success('删除质评质保信息成功！');
                this.getQuality();
            },
            handleRemoveBim(file, fileList) {
                this.BimList=fileList
            },
            Bimchange(file, fileList){
                this.BimList=fileList
            },
            handleRemove(file, fileList) {
                this.fileList=fileList
            },
            filechange(file,fileList){
                this.fileList=fileList
            },
            handleRemove1(file, fileList) {
                this.fileList1=fileList
            },
            filechange1(file,fileList){
                this.fileList1=fileList
            },
            handleRemove2(file, fileList) {
                this.fileList2=fileList
            },
            filechange2(file,fileList){
                this.fileList2=fileList
            },
            handleRemove3(file, fileList) {
                this.fileList3=fileList
            },
            filechange3(file,fileList){
                this.fileList3=fileList
            },
            handleRemove4(file, fileList) {
                this.fileList4=fileList
            },
            filechange4(file,fileList){
                this.fileList4=fileList
            },
            handleRemoveEdit(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd.length; i++) {
                        if (this.fileListEditAdd[i] == file) {
                            this.fileListEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            filechangeEdit(file,fileList){
                this.fileListEditAdd.push(file)
                this.fileListEdit=fileList
            },
            handleRemoveEdit1(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID1.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd1.length; i++) {
                        if (this.fileListEditAdd1[i] == file) {
                            this.fileListEditAdd1.splice(i,1)
                        }
                    }
                }
                this.fileListEdit1=fileList
            },
            filechangeEdit1(file,fileList){
                this.fileListEditAdd1.push(file)
                this.fileListEdit1=fileList
            },
            handleRemoveEdit2(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID2.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd2.length; i++) {
                        if (this.fileListEditAdd2[i] == file) {
                            this.fileListEditAdd2.splice(i,1)
                        }
                    }
                }
                this.fileListEdit2=fileList
            },
            filechangeEdit2(file,fileList){
                this.fileListEditAdd2.push(file)
                this.fileListEdit2=fileList
            },
            handleRemoveEdit3(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID3.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd3.length; i++) {
                        if (this.fileListEditAdd3[i] == file) {
                            this.fileListEditAdd3.splice(i,1)
                        }
                    }
                }
                this.fileListEdit3=fileList
            },
            filechangeEdit3(file,fileList){
                this.fileListEditAdd3.push(file)
                this.fileListEdit3=fileList
            },
            handleRemoveEdit4(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID4.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd4.length; i++) {
                        if (this.fileListEditAdd4[i] == file) {
                            this.fileListEditAdd4.splice(i,1)
                        }
                    }
                }
                this.fileListEdit4=fileList
            },
            filechangeEdit4(file,fileList){
                this.fileListEditAdd4.push(file)
                this.fileListEdit4=fileList
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
           
            editdialogClose(){
                this.$refs.editFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.CheckChange()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.CheckChange()
            },
            handleClick(){}
        },
        created(){
          this.getProApp();
          this.getQuality();
          this.getIshege();
        },
        mounted() {
          // this.addmap();
            this.showBim();
        },
        components: {
            qualityPlanAdd,
            createEcharts,
            updateEcharts
        },
    }
</script>

<style scoped lang="less">
    #quality {
        position: absolute;
        left: 220px;
        right: 20px;
        top: 80px;
        bottom: 20px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
   .bim3d{
        width:830px;
        height: 420px;
        background-color: white;
       z-index: 99999999;
        border: 1px solid #e3ecfb;
    }
    .cad3d{
        width:830px;
        background-color: white;
        height: 420px;
        border: 1px solid #e3ecfb;
        .cadheader{
            width: 100%;
            height:75px;
            border-bottom: 1px solid #ffd700;
            display: flex;
            justify-content:space-between;
            div{
                margin-left: 40px;
                margin-right: 40px;
                width: 222px;
                text-align: center;
                p{
                    font-size: 28px;
                    font-weight: bold;
                    color: #ffd700;
                    margin-bottom: 5px;
                }
                span{
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                }
            }
        }

        #createUserNum{
            width: 100%;
            height: 170px;
        }
        #updateUserNum{
            width: 100%;
            height: 170px;
        }
    }
    .qtable{
        width: 1698px;
        height: 410px;
        background-color: white;
        border: 1px solid #e3ecfb;
        display: flex;
        justify-content: space-between;
        /deep/ .el-card {
            background-color: white;
            border-bottom: 1px solid #4988e3;
            color: black;
            height: 100%;
            width: 100%;
        }
      .addzpzb{
        margin-top: -5px;
      }

      /deep/ .el-button{

        height: 36px !important;
        padding: 2px 6px !important;
        span {
          color: black;
          font-size: 14px !important;
        }
      }
        /deep/ .el-card__header {
            border-bottom: 1px solid #4988e3;
            background-color: white;
            height: 48px;
            padding-top: 3px;
            padding-left: 15px;
            /deep/   .clearfix {
                margin-top: -15px;
                padding-left: 5px;
                height: 46px;
                border-left: 0px solid white;
                span {
                    color: black;
                    font-size: 14px;
                }

            }

        }
        .serverTable{
            width: 99.5%;
            height: 320px;
        }
    }

    /deep/  .el-card__body{
        padding: 0;
    }

    /deep/ .el-tabs__content{
        height: 285px;
    }
    /deep/ .el-tabs__header{
        margin: 0;
    }
    /deep/ .is-active{
    }
    /deep/ .el-tabs__item{
        color: #eef5ff;
    }
    /deep/ .el-tabs__item:hover {
        color: #409EFF;
        cursor: pointer;
    }
    /deep/ .el-tabs__item.is-active {
        color: #409EFF;
    }
    .yingxiang{
        position:absolute;left: 680px;margin-top: 385px;font-size: 14px;z-index: 99999999999
    }
    .yingxiangfull{
        position:absolute;right: 85px;bottom: 5px;font-size: 14px;z-index: 99999999999
    }
    .full{
        position:absolute;left: 760px;margin-top: 385px;font-size: 14px;z-index: 99999999999
    }
    .suoxiao{
        position:absolute;right: 5px;bottom: 5px;font-size: 14px;z-index: 99999999999
    }
    /deep/ .el-dialog__wrapper{
        z-index: 20122222021222 !important;
    }

</style>
<style>

     .el-message-box__wrapper {
        z-index: 20122222021222 !important;
    }

</style>
