<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">新增相关方台账</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :empty-text="loadInfo"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                          <el-table-column prop="fileNo"  label="文件号"  width="120">  
                        </el-table-column>
                        <el-table-column prop="fileTheme"  label="文件名称"  width="220">  
                        </el-table-column>
                        <el-table-column prop="sendUnit"  label="发文单位"  width="150" >  
                        </el-table-column>
                        <el-table-column prop="receiveUnit"  label="签收单位"  width="150" >  
                        </el-table-column>
                        <el-table-column prop="sendUser"  label="签发人"  width="120" >  
                        </el-table-column>
                        <el-table-column prop="receiveUser"  label="签收人"  width="120" >  
                        </el-table-column>
                        <el-table-column prop="sendTime"  label="签收时间"  width="200" >  
                        </el-table-column>
                        <el-table-column prop="files"  label="附件"  width="150" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.filePath)" v-if="scope.row.filePath != null">  {{scope.row.fileTheme}}</a>
                                <span v-else> 暂无附件</span>
                             </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <!-- <el-button  type="primary" @click="xiangqing(scope.row)"  size="mini">修改</el-button> -->
                                <!-- <el-button  type="primary" @click="downFile(scope.row.filePath,scope.row.fileTheme)"  size="mini">下载</el-button> -->
                                <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                                <!-- <el-button  type="primary" @click="quxiao(scope.row)"  size="mini" >取消</el-button>
                                <el-button  type="primary" @click="beforeuploadModel(scope.row)"  size="mini" >附件上传</el-button>
                                <el-button  type="primary" @click="downFile(scope.row)"  size="mini" >附件下载</el-button > -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
   
        <el-dialog :visible.sync="writeForm" title="新增相关方台账" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="140px" :inline="true" >
                <el-form-item label="文件号" prop="fileNo">
                    <el-input  v-model="addForm.fileNo"></el-input>
                </el-form-item>
                <el-form-item label="文件名称" prop="fileTheme">
                    <el-input  v-model="addForm.fileTheme"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="phone">
                    <el-input  v-model="addForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="签收单位" prop="receiveUnit">
                    <el-input  v-model="addForm.receiveUnit"></el-input>
                </el-form-item>
                <el-form-item label="签收人" prop="receiveUser">
                    <el-input  v-model="addForm.receiveUser"></el-input>
                </el-form-item>
                <el-form-item label="发文单位" prop="sendUnit">
                    <el-input  v-model="addForm.sendUnit"></el-input>
                </el-form-item>
                <el-form-item label="签发人" prop="sendUser">
                    <el-input  v-model="addForm.sendUser"></el-input>
                </el-form-item>
                <el-form-item label="签收时间" prop="sendTime">
                    <el-date-picker
                        v-model="addForm.sendTime"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input  v-model="addForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                     </el-upload>
                     <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>

        <el-dialog :visible.sync="writeForm2" title="查看详情" width="50%" >
            <el-form ref="addFormRef" :model="editform" label-width="140px" :inline="true" >
                <el-form-item label="文件号" prop="fileNo">
                    <el-input  v-model="editform.fileNo"></el-input>
                </el-form-item>
                <el-form-item label="文件主题" prop="fileTheme">
                    <el-input  v-model="editform.fileTheme"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="phone">
                    <el-input  v-model="editform.phone"></el-input>
                </el-form-item>
                <el-form-item label="签收单位" prop="receiveUnit">
                    <el-input  v-model="editform.receiveUnit"></el-input>
                </el-form-item>
                <el-form-item label="签收人" prop="receiveUser">
                    <el-input  v-model="editform.receiveUser"></el-input>
                </el-form-item>
                <el-form-item label="发文单位" prop="sendUnit">
                    <el-input  v-model="editform.sendUnit"></el-input>
                </el-form-item>
                <el-form-item label="发送人" prop="sendUser">
                    <el-input  v-model="editform.sendUser"></el-input>
                </el-form-item>
                <el-form-item label="签收时间" prop="sendTime">
                    <el-date-picker
                        v-model="editform.sendTime"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input  v-model="editform.remark"></el-input>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm2 = false">关 闭</el-button>
                                  <el-button @click="xiugaiModel">保 存</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                loadbut:false,
                loadbutext:"保 存",
                loadInfo:"数据加载中...",
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                writeForm2:false,
                fileList:[],
                menuId:window.sessionStorage.getItem('menuId'),
                editform:{

                },
                progress: 0,
                percentage:0,
                addForm:{
                    projectId:this.$root.proId,
                    attacheIds:[],
                    fileTheme:'',
                    phone:'',
                    receiveUnit:'',
                    receiveUser:'',
                    remark:'',
                    sendTime:'',
                    sendUnit:'',
                    sendUser:'',
                    fileNo:''
                },
                
                hegenum:0,
                buhegenum:0,
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
                },
                editrow:{}
            }
        },
        created(){
            this.getLeftMenu()
            this.getProApp()
            const load = this.$loading({
            lock:true,
            text:'Loading',
            spinner:'el-icon-loading',
            background:'rgba(0,0,0,0.7)'
            });
            load.close();
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-qualitative/contact/findAllByProjectId?projectId='+this.$root.proId)
              this.tableData=res.data
              this.loadInfo = ''
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=51')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            arraySpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    const _row = this.flitterData(this.tableData).one[rowIndex]
                    return {
                    rowspan: _row,
                    colspan: 1,
                    }
                }
                if (columnIndex === 1) {
                    const _row1 = this.flitterData2(this.tableData).one[rowIndex]
                    return {
                    rowspan: _row1,
                    colspan: 1,
                    }
                }
                if (columnIndex === 2) {
                    const _row1 = this.flitterData3(this.tableData).one[rowIndex]
                    return {
                    rowspan: _row1,
                    colspan: 1,
                    }
                }
            },
            flitterData(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.unitConstruction === arr[index - 1].unitConstruction) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            flitterData2(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.substructure === arr[index - 1].substructure) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            flitterData3(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.subItem.split('-')[1] === arr[index - 1].subItem.split('-')[1]) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            async hege(row){
                console.log(row)
                delete row.files
                row.eligibility=1;
                row.attacheIds=[];
                const {data:res}= await this.$http1.put('/v1.0/enterprises/project-qualitative/update',row)
                if(res.businessCode==4300){
                    this.$message.success("合格成功");
                    this.getProApp()
                    this.getTongji()
                }else{
                this.$message.error("合格失败！");
                }
            },
            async buhege(row){
                console.log(row)
                delete row.files
                row.eligibility=2;
                row.attacheIds=[];
                const {data:res}= await this.$http1.put('/v1.0/enterprises/project-qualitative/update',row)
                if(res.businessCode==4300){
                    this.$message.success("不合格成功");
                    this.getProApp()
                    this.getTongji()
                }else{
                this.$message.error("不合格失败！");
                }
            },
            xiangqing(row){
                this.editform=row
                this.writeForm2=true
            },
            async xiugaiModel(){
           
           this.editform.attacheIds=[]
                // for(let i in this.editform.files){
                //     this.editform.attacheIds.push(this.editform.files[i].attacheId)
                // }
                // delete this.editform.files;
                // console.log(this.editform)
                const { data: res } = await this.$http1.put('/v1.0/enterprises/project-contractor/update', this.editform)
                if(res.businessCode==4300){
                    this.$message.success("提交成功");
                    this.getProApp()
                    this.writeForm2 = false;
                }else{
                    this.$message.error("提交失败！");
                }
                },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                var data = {"ids":[row.id]}
                console.log(data)
                const {data:res}= await this.$http1.post('/v1.0/enterprises/project-qualitative/contact/delete',data)
                if(res.code!= 200){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
            async yulan(path){
                console.log(path)
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async deleteModel(){

            },
            async downFile(row){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:row.files[0].filePath },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = row.files[0].fileName;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(row){
                this.fileList=[]
                this.writeForm=true
                this.editrow=row
            },
           async uploadModel(){
            this.loadbut = true
            this.loadbutext = "提交中..."
            var formData = new FormData();
            for(let i in this.fileList){
                formData.append("file", this.fileList[i].raw);
                formData.append("fileName", this.addForm.fileTheme);
                formData.append("fileNo", this.addForm.fileNo);
            }
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(this.percentage)
                    }
                    }
                };
            if(this.fileList!=[]){
                const { data: res2 } = await this.$http1.post('v1.0/enterprises/project-qualitative/contact/upload/contactFile', formData,config)
                if(res2.businessCode==1000){
                this.addForm.fileId=[]
                this.addForm.fileId = res2.data
            }
            const { data: res } = await this.$http1.post('v1.0/enterprises/project-qualitative/contact/save', this.addForm)
            if(res.code==200){
                this.$message.success("提交成功");
                this.getProApp()
                this.percentage = 0
                this.loadbut = false
                this.loadbutext = "保 存"
                this.writeForm = false;
            }else{
              this.$message.error("提交失败！");
            }
           }
            // var formData = new FormData();
            //     formData.append("file", this.fileList[0].raw);
            // formData.append("projectId", this.$root.proId);
            // const { data: res } = await this.$http1.post('/v1.0/enterprises/project-qualitative/import/ProjectQualitative', formData)
            // if(res.businessCode==4300){
            //     this.$message.success("提交成功");
            //     this.getProApp()
            //     this.writeForm = false;
            // }else{
            //   this.$message.error("提交失败！");
            // }
          }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 90.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
.btnheader{
    display: flex;
    .tag{
        height: 30px;
        margin-left:20px;
        margin-top: 8px;
    }
}
  </style>
  