<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <div class="homecontent">
            <div id="popup2" class="ol-popup"  v-show="popupshow" >
                <a href="#" @click="closePop" class="popx">x</a>
                <div class="media">
                    <div class="media-body">
                        <table class="poptable">
                            <tbody>
                            <tr>
                                <th width="28%">项目名称</th>
                                <td colspan="2">{{pro.projectName}}</td>
                            </tr>
                            <tr>
                                <th>项目类别</th>
                                <td colspan="2">{{pro.projectType.typeName}}</td>

                            </tr>
                            <tr>
                                <th>项目地址</th>
                                <td colspan="2">{{pro.projectAddress}}</td>
                            </tr>
                            <tr>
                                <th>计划结束时间</th>
                                <td colspan="2">{{pro.planEndDate}}</td>
                            </tr>
                         <!--   <tr>
                                <th>项目照片</th>
                                <td colspan="2">   <el-image
                                        style="width: 100px; height: 80px"
                                        :src="pro.picmapurl"
                                        :preview-src-list="pro.srcmapList">
                                </el-image></td>
                            </tr>-->

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="contentLeft">
                <div class="contentTop" >
                    <div>
                        <p>项目总数</p>
                        <span><span class="pronum">{{numfrom.totalCount}}</span><span class="prounit">个</span></span>
                    </div>
                    <div>
                        <p>新签项目</p>
                        <span><span class="pronum">{{numfrom.signCount}}</span><span class="prounit">个</span></span>
                    </div>
                    <div>
                        <p>在建项目</p>
                        <span><span class="pronum">{{numfrom.buildingCount}}</span><span class="prounit">个</span></span>
                    </div>
                    <div>
                        <p>完成项目</p>
                        <span><span class="pronum">{{numfrom.completeCount}}</span><span class="prounit">个</span></span>
                    </div>
                    <div class="zong">
                        <p>项目总投资</p>
                        <span><span class="pronum">{{numfrom.totalInvestment}}</span><span class="prounit">亿</span> </span>
                    </div>
                </div>
                <div class="contentCenter">
                    <div id="map"></div>
                </div>
            </div>
            <div class="contentRight">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>项目列表</span>
                        <el-input v-model="pronameSelect" placeholder="请输入名称" @input="changeName"></el-input>
                        <el-input v-model="proAddressSelect" placeholder="请输入地址" @input="changeAddress"></el-input>
                    </div>
                    <div class="crad-body" >
                        <div class="proDiv" v-for="item in proData2" :key="item.id" @click="proInfo(item.id,item.projectName)">
                            <div class="proDivheader">
                                <span :title=item.projectName>{{item.projectName}}</span>    <img  @click="proInfo(item.id)" src="../assets/img/homepage_new/箭头_1.png" >
                            </div>
                            <div class="proDivcontent">
                                <p>项目地址：<span  :title=item.projectAddress>{{item.projectAddress}}</span> </p>
                                <p class="proTypeP"><span>项目类型：{{item.projectType.typeName}}</span>  <span>项目总投资：{{item.totalInvestment}}亿</span>   </p>
                            </div>
                            <div class="proDivbottom">
                               <span>
                                   项目进度</span><span>{{item.jindunum}} %</span>
                                <div class="processDiv">
                                    <div class="realProcess" :style="'width:' + item.jindunum + '%'"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="contentBottom">
                <div class="bottomHeader">
                    <div  class="zhu" >
                        <span>项目资金情况汇总（单位：元）</span>
                        <!-- <el-select v-model="valueYear" placeholder="请选择年份"  @change="changeYear" style="z-index: 9999;width: 100px;margin-top: -5px" v-show="show==1">
                            <el-option
                                    v-for="item in optionsYear"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select> -->
                    </div>
                    <!-- <div class="choose" >
                        <el-select v-model="value" placeholder="请选择"  @change="changeType">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                </div>
                <el-descriptions title="" border :column="4" direction="vertical">
                        <el-descriptions-item label="合同总额">{{tableData.contractSumTotal}}</el-descriptions-item>
                        <el-descriptions-item label="工程进度计量">{{tableData.progressMeasurementTotal}}</el-descriptions-item>
                        <el-descriptions-item label="项目应收">{{tableData.projectReceivableTotal}}</el-descriptions-item>
                        <el-descriptions-item label="项目实际收款">{{tableData.actualCollectionTotal}}</el-descriptions-item>
                        <el-descriptions-item label="项目拨款">{{tableData.projectAppropriationTotal}}</el-descriptions-item>
                        <el-descriptions-item label="账上余额">{{tableData.balanceAccountTotal}}</el-descriptions-item>
                        <el-descriptions-item label="剩余应收款项">{{tableData.remainingReceivablesTotal}}</el-descriptions-item>
                   </el-descriptions>
            </div>
            <div class="tuli">
                 <div>
                        <img src="../assets/img/homepage_new/定位.png" style="">
                        <el-badge :value="doingNum" class="item" type="success">
                        <span style=""> 正在进行中</span>
                        </el-badge>
                        <i v-if="doingShow" class="el-icon-view" @click="doingClick"></i>
                        <i v-else class="el-icon-noshowyincang iconfont" @click="doingClick"></i>
                </div>
                <div >
                    <img src="../assets/img/homepage_new/定位blue.png" >
                    <el-badge :value="doneNum" class="item" type="primary">
                    <span > 已完成项目</span>
                    </el-badge>
                    <i v-if="doneShow" class="el-icon-view" @click="doneClick"></i>
                    <i v-else   class="el-icon-noshowyincang iconfont" @click="doneClick"></i>
                </div>
                <!-- <div >
                    <img src="../assets/img/homepage_new/定位yeelow.png" >
                    <el-badge :value="todoNum" class="item" type="warning">
                        <span> 未开始项目</span>
                    </el-badge>
                    <i v-if="todoShow" class="el-icon-view" @click="todoClick"></i>
                    <i v-else   class="el-icon-noshowyincang iconfont" @click="todoClick"></i>
                </div>
                <div  >
                    <img src="../assets/img/homepage_new/定位red.png" >
                    <el-badge :value="notdoNum" class="item">
                        <span> 已超期项目</span>
                    </el-badge>
                    <i v-if="notdoShow" class="el-icon-view" @click="notdoClick"></i>
                    <i v-else   class="el-icon-noshowyincang iconfont" @click="notdoClick"></i>
                </div> -->
            </div>
            <div class="echartsDiv">
                <homeEchartsTouzi/>
            </div>
        </div>
    </div>
</template>

<script>
    import "../assets/css/ol1.css";
    import "../assets/css/ol2.css";
    import Map from 'ol/Map';
    import View from 'ol/View';
    import TileLayer from 'ol/layer/Tile'
    import * as control from 'ol/control';
    import * as olProj from 'ol/proj';
    import * as olExtent from 'ol/extent';
    import WMTS from 'ol/source/WMTS';
    import WMTSTileGrid from 'ol/tilegrid/WMTS';
    import VectorSource from 'ol/source/Vector';
    import VectorLayer from 'ol/layer/Vector';
    import Style from 'ol/style/Style';
    import GeoJSON from 'ol/format/GeoJSON';
    import Stroke from 'ol/style/Stroke';
    import Text from 'ol/style/Text';
    import Fill from 'ol/style/Fill';
    import Icon from 'ol/style/Icon';
    import Point  from 'ol/geom/Point';
    import Feature from 'ol/Feature';
    import Overlay from 'ol/Overlay';
    import {fromExtent} from 'ol/geom/Polygon';
    import LinearRing  from 'ol/geom/LinearRing';
    import homeEcharts from "./echarts/homeEcharts";
    import homeEchartsPro from "./echarts/homeEchartsPro";
    import homeEchartsShi from "./echarts/homeEchartsShi";
    import jinduEcharts from "./echarts/jinduEcharts";
    import vueSeamlessScroll from 'vue-seamless-scroll';
    import homeEchartsTouzi from "./echarts/homeEchartsTouzi";
    var map,pop1;
    var doingSource= new VectorSource();
    var doingLayer = new VectorLayer({
        source: doingSource,
    });
    var doneSource= new VectorSource();
    var doneLayer = new VectorLayer({
        source: doneSource,
    });
    var todoSource= new VectorSource();
    var todoLayer = new VectorLayer({
        source: todoSource,
    });
    var notdoSource= new VectorSource();
    var notdoLayer = new VectorLayer({
        source: notdoSource,
    });

    var doingSource2= new VectorSource();
    var doingLayer2 = new VectorLayer({
        source: doingSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位.png'),
            }))
        })
    });
    var doneSource2= new VectorSource();
    var doneLayer2 = new VectorLayer({
        source: doneSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位blue.png'),
            }))
        })
    });
    var todoSource2= new VectorSource();
    var todoLayer2 = new VectorLayer({
        source: todoSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位yeelow.png'),
            }))
        })
    });
    var notdoSource2= new VectorSource();
    var notdoLayer2 = new VectorLayer({
        source: notdoSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位red.png'),
            }))
        })
    });
    var createdoingStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位.png'),
            }))
        });
    };
    var createdoneStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位blue.png'),
            }))
        });
    };
    var createtodoStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位yeelow.png'),
            }))
        });
    };
    var createnotdoStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位red.png'),
            }))
        });
    };
    var mystyle = new Style({
        fill: new Fill({
            color:"rgba(255,255,255, 0.9)",
        }),
        stroke: new Stroke({
            color:"#BDBDBD",
            width:2
        })
    });
    export default {
        name: "homepage_new",
        data(){
            return{
                tableData:{
                    contractSumTotal:'',
                    "progressMeasurementTotal": null,
                    "projectReceivableTotal": null,
                    "actualCollectionTotal": null,
                    "projectAppropriationTotal": null,
                    "balanceAccountTotal": null,
                    "projectAppropriation": null,
                    "remainingReceivablesTotal": null,
                    "remainingReceivables": null,
                    performanceBond:null,
                    performanceDeadlines:null,
                    qualityAssurance:null,
                    qualityDeadlines:null,
                },
                options: [{
                    value: '1',
                    label: '财务统计'
                },  {
                    value: '2',
                    label: '项目统计'
                },{
                    value: '3',
                    label: '重点区域统计'
                }, {
                    value: '4',
                    label: '新闻大事记'
                }, {
                    value: '5',
                    label: '项目进度展示'
                }],
                pronameSelect:'',
                proAddressSelect:'',
                optionsYear:[],
                valueYear:'2023',
                yearData:0,
                value: '1',
                proData:[],
                proData2:[],
                popupshow:false,
                pro:{
                    projectName:'',
                    projectType:{
                        typeName:''
                    },
                    projectAddress:'',
                    planEndDate:'',
                    picmapurl:'',
                    srcmapList:[],
                },
                numfrom:{

                },
                show:1,
                items:[ //消息列表对应的数组
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"关于严明2021年五一端午廉洁自律要求的通知.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"关于严明2021年五一端午廉洁自律要求的通知.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/关于严明2021年五一端午廉洁自律要求的通知.pdf',name:"关于严明2021年五一端午廉洁自律要求的通知.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/关于严明2021年五一端午廉洁自律要求的通知.pdf',name:"关于严明2021年五一端午廉洁自律要求的通知.pdf"},
                    {url:'http://epc.xjbtgis.cn/static/doc/习总书记安全生产重要论述学习摘篇.pdf',name:"习总书记安全生产重要论述学习摘篇.pdf"},
                ],
                processData:[],
                doingNum:0,
                doneNum:0,
                todoNum:0,
                notdoNum:0,
                doingShow:true,
                doneShow:true,
                todoShow:true,
                notdoShow:true,
                zhuchuData:[],
                shouruData:[],
                typeData:[]
            }
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-progress/projectStatisticsAll')
              if(res.businessCode==3000){
                this.tableData=res.data
              }
              
            },
            async getTongji(){
                const {data:res}= await this.$http1.get('/v1.0/project/statistics/findAllByProjectId')
                if(res.businessCode!='3000'){
                    return this.$message.error('查询EPC项目个数失败！'+res.message)
                }
                this.numfrom=res.data;
                this.numfrom.totalInvestment=this.numfrom.totalInvestment.toFixed(4)
            },
            async initMap(){
                map = new Map({
                    target: 'map',
                    view: new View({
                        center: [106.28838135,38.209817352],
                        zoom: 4.5,
                        projection: 'EPSG:4326'
                    }),
                });
                var projection = olProj.get('EPSG:4326');
                var projectionExtent = projection.getExtent();
                var size = olExtent.getWidth(projectionExtent) / 256;
                var resolutions = new Array(19);
                var matrixIds = new Array(19);
                for(var z = 0; z < 19; z++) {
                    // generate resolutions and matrixIds arrays for this WMTS
                    //resolutions[z] = size / Math.pow(2, z);
                    matrixIds[z] = z;
                }
                resolutions = [1.40625, 0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625, 0.0006866455078125, 0.00034332275390625, 0.000171661376953125,
                    0.0000858306884765625, 0.00004291534423828125, 0.000021457672119140625, 0.000010728836059570312, 0.000005364418029785156, 0.000002682209014892578, 0.000001341104507446289
                ];
                var webKey = "b0f80d188ee3100e0327398d8afb39cd";
                var tdtsldtLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/vec_c/wmts?tk=" + webKey,
                        layer: 'vec',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtslzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cva_c/wmts?tk=" + webKey,
                        layer: 'cva',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cia_c/wmts?tk=" + webKey,
                        layer: 'cia',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/img_c/wmts?tk=" + webKey,
                        layer: 'img',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                map.addLayer(tdtsldtLayer)
                map.addLayer(tdtslzjLayer)
                //  map.addLayer(tdtyxLayer);
                //map.addLayer(tdtyxzjLayer);
                var converLayer = new VectorLayer({
                    source: new VectorSource(),
                    style: mystyle
                });
                //  map.addLayer(converLayer)
                const {data:result_xj}= await this.$httpaxios.get('./china_R.json')
                var fts = new GeoJSON().readFeatures(result_xj);
                var ft = fts[0];
                var converGeom = this.erase(ft.getGeometry());
                var convertFt = new Feature({
                    geometry: converGeom
                })
                converLayer.getSource().addFeature(convertFt);
                var xjSource = new VectorSource({
                   // url: 'http://localhost:8086/china_R.json',
                    url:'./china_R.json',
                    format: new GeoJSON(),
                    wrapX: false
                });
                var xjLayer = new VectorLayer({
                    source: xjSource,
                    style: new Style({
                        stroke: new Stroke({
                            //color: '#0033ff',
                            color: 'rgb(0, 186 ,255)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 186 ,255, 0.01)'
                        })
                    })
                });
                map.addLayer(xjLayer)
                notdoLayer.setVisible(false)
                doneLayer.setVisible(false)
                todoLayer.setVisible(false)
                doingLayer.setVisible(false)
                // map.addLayer(notdoLayer);
                map.addLayer(doneLayer);
                // map.addLayer(todoLayer);
                map.addLayer(doingLayer);
                // map.addLayer(notdoLayer2);
                map.addLayer(doneLayer2);
                // map.addLayer(todoLayer2);
                map.addLayer(doingLayer2);
                pop1 = new Overlay({
                    element: document.getElementById('popup2'),
                    autoPan: true,
                    autoPanAnimation: {
                        duration: 250
                    },
                    offset: [0, -20]
                });
                map.on('click',evt=> {
                    var coordinate = evt.coordinate;
                    map.forEachFeatureAtPixel(evt.pixel, (cxfeature, layerVetor)=> {
                        if(layerVetor == gjLayer) {
                            this.pro.projectName=cxfeature.values_.projectName;
                            this.pro.projectType.typeName=cxfeature.values_.typeName;
                            this.pro.projectAddress=cxfeature.values_.projectAddress;
                            this.pro.planEndDate=cxfeature.values_.planEndDate;
                            this.pro.picmapurl=cxfeature.values_.picmapurl;
                            this.pro.srcmapList=cxfeature.values_.srcmapList;
                            this.popupshow=true;
                            map.addOverlay(pop1);
                            pop1.setPosition(coordinate);
                            map.getView().setCenter(coordinate)
                        }
                    })
                })
                map.getView().on('change:resolution', function() {
                    console.log(map.getView().getZoom())
                    if(map.getView().getZoom() < 6){
                        notdoLayer2.setVisible(true);
                        doneLayer2.setVisible(true);
                        todoLayer2.setVisible(true);
                        doingLayer2.setVisible(true);
                        notdoLayer.setVisible(false);
                        doneLayer.setVisible(false);
                        todoLayer.setVisible(false);
                        doingLayer.setVisible(false);
                    }else {
                        notdoLayer2.setVisible(false);
                        doneLayer2.setVisible(false);
                        todoLayer2.setVisible(false);
                        doingLayer2.setVisible(false);
                        notdoLayer.setVisible(true);
                        doneLayer.setVisible(true);
                        todoLayer.setVisible(true);
                        doingLayer.setVisible(true);
                    }
                })
            },
            erase(geom) {
                var extent = [-180,-90,180,90];
                var polygonRing =new fromExtent(extent);
                var coords = geom.getCoordinates();
                for(let i in coords){
                    var linearRing = new LinearRing(coords[i][0]);
                    polygonRing.appendLinearRing(linearRing);
                }
                console.log(polygonRing)
                return polygonRing;
            },
            doingClick(){
                this.doingShow=!this.doingShow;
                if(this.doingShow){
                    if(map.getView().getZoom() < 6){
                        doingLayer2.setVisible(true);
                    }else {
                        doingLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        doingLayer2.setVisible(false);
                    }else {
                        doingLayer.setVisible(false);
                    }
                }
            },
            doneClick(){
                this.doneShow=!this.doneShow;
                if(this.doneShow){
                    if(map.getView().getZoom() < 6){
                        doneLayer2.setVisible(true);
                    }else {
                        doneLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        doneLayer2.setVisible(false);
                    }else {
                        doneLayer.setVisible(false);
                    }
                }
            },
            todoClick(){
                this.todoShow=!this.todoShow;
                if(this.todoShow){
                    if(map.getView().getZoom() < 6){
                        todoLayer2.setVisible(true);
                    }else {
                        todoLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        todoLayer2.setVisible(false);
                    }else {
                        todoLayer.setVisible(false);
                    }
                }
            },
            notdoClick(){
                this.notdoShow=!this.notdoShow;
                if(this.notdoShow){
                    if(map.getView().getZoom() < 6){
                        notdoLayer2.setVisible(true);
                    }else {
                        notdoLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        notdoLayer2.setVisible(false);
                    }else {
                        notdoLayer.setVisible(false);
                    }
                }
            },
            async getpross(){
                const {data:res2}= await this.$http6.get('/v1.0/schedule/statistics/queryAvgCompleteRateStatistics')
                if(res2.businessCode!='3000'){
                     this.$message.error(res2.message)
                    return  this.processData==[]
                }
                this.processData=res2.data
            },
            async getProtype(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
                this.typeData=res.data
            },
            async getProInfo(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAll')
                if(res.businessCode!='3000'){
                    return this.$message.error('查询EPC项目失败！'+res.message)
                }
                this.proData=[]
              
                const {data:res2}= await this.$http0.get('/v1/account/user/query/findUserProject?userId='+ window.sessionStorage.getItem("userid"))
                for(let i in res2.data){
                    for(let j in res.data){
                        if(res2.data[i].projectId==res.data[j].id){
                            this.proData.push(res.data[j])
                        }
                    }
                }
                this.proData2=this.proData
                window.sessionStorage.setItem('proName','')
                //this.proData=res.data;
                var numprocee=0;
                for(let i in this.proData){
                    this.proData[i]['projectType']=this.typeData.find(o => o.id == this.proData[i].projectTypeId)
                    numprocee=0
                    for(let j in this.processData){
                        if(this.processData[j].projectId==this.proData[i].id){
                            numprocee=Number((Number(this.processData[j].avgCompleteRate)*100).toFixed(2))
                            this.proData[i].jindunum=numprocee
                        }else {
                            this.proData[i].jindunum=numprocee
                        }
                    }
                    if(this.proData[i].actualEndDate){
                        this.proData[i].tianshu='已完成'
                    }else {
                        if(new Date(this.proData[i].planStartDate.replace('-','/'))-new Date()>0){
                            this.proData[i].tianshu='未开始'
                        }else {
                            var time=((new Date(this.proData[i].planEndDate.replace('-','/'))-new Date())/(1*24*60*60*1000)).toFixed(0);
                            time=time-1
                            if(time<0){
                                this.proData[i].tianshu='已超期'
                            }else {
                                this.proData[i].tianshu='正在进行中'
                            }
                    }
                }}
                var tianshu,status;
                doneSource.clear()
                todoSource.clear()
                doingSource.clear()
                notdoSource.clear()
                doneSource2.clear()
                todoSource2.clear()
                doingSource2.clear()
                notdoSource2.clear()
                this.doneNum=0
                this.todoNum=0
                this.notdoNum=0
                this.doingNum=0
                for(let i in this.proData){
                    if(this.proData[i].actualEndDate){
                        tianshu='已完成'
                        status=0;
                    }else {
                        if(new Date(this.proData[i].planStartDate.replace('-','/'))-new Date()>0){
                            tianshu='未开始'
                            status=1;
                        }else {
                            var time=((new Date(this.proData[i].planEndDate.replace('-','/'))-new Date())/(1*24*60*60*1000)).toFixed(0);
                            if(time<0){
                                tianshu='已超期'+time+'天'
                                status=2;
                            }else {
                                tianshu='计划还剩'+time+'天'
                                status=3;
                            }
                        }
                    }
                    var jingdu = (Math.abs(this.proData[i].longitude) + (Math.abs(this.proData[i].longitudeMinute) / 60 + Math.abs(this.proData[i].longitudeSecond) / 3600));
                    var weidu = (Math.abs(this.proData[i].latitude) + (Math.abs(this.proData[i].latitudeMinute) / 60 + Math.abs(this.proData[i].latitudeSecond) / 3600));

                    var gjFeature = new Feature({
                        geometry: new Point([Number(jingdu),Number(weidu)]), //几何信息
                        name:this.proData[i].projectName+'--'+tianshu,
                        projectName:this.proData[i].projectName,
                        typeName:this.proData[i].projectType.typeName,
                        projectAddress:this.proData[i].projectAddress,
                        planEndDate:this.proData[i].planEndDate,
                      /*  picmapurl:this.proData[i].files[0].filePath,
                        srcmapList:this.proData[i].files*/
                    });
                    var gjFeature2 = new Feature({
                        geometry: new Point([Number(this.proData[i].longitude),Number(this.proData[i].latitude)]), //几何信息
                    });
                    if(status==0){
                        doneSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createdoneStyle(gjFeature));
                        doneSource.addFeature(gjFeature)
                        this.doneNum++
                    }else if(status==1){
                        todoSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createtodoStyle(gjFeature));
                        todoSource.addFeature(gjFeature)
                        this.todoNum++
                    }else if(status==2){
                        notdoSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createnotdoStyle(gjFeature));
                        notdoSource.addFeature(gjFeature)
                        this.notdoNum++
                    }else if(status==3){
                        doingSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createdoingStyle(gjFeature));
                        doingSource.addFeature(gjFeature)
                        this.doingNum++;
                    }
                }
            },
            proInfo(id,projectName){
                window.sessionStorage.setItem('proId',id)
                window.sessionStorage.setItem('proName',projectName)
                this.$root.proId=id
                //  window.sessionStorage.setItem('current',JSON.parse(window.sessionStorage.getItem("menulevel1"))[0].path)
                window.sessionStorage.setItem('current','/project')
                 this.$router.push({ path: "/project" });
                console.log( JSON.parse(window.sessionStorage.getItem("menulevel1"))[0].path)
                //  this.$router.push({ path:  JSON.parse(window.sessionStorage.getItem("menulevel2"))[0][0].path});

            },
            closePop(){
                this.popupshow=false;
            },
            changeType(type){
                this.show=type
                if(type==1){
                    this.getYear()
                }
            },
            async getYear(){
                const {data:res}= await this.$http5.get('/v1.0/cost/statistics/queryCostStatistics')
                this.zhuchuData=res.data.payment
                this.shouruData=res.data.receipt
                this.optionsYear=[]
                for(let i in res.data.payment){
                    this.optionsYear.push({
                        value: res.data.payment[i].年度,
                        label: res.data.payment[i].年度
                    })
                }
                this.optionsYear=[...new Set(this.optionsYear)]
                this.valueYear=this.optionsYear[this.optionsYear.length-1].value
                var cnum = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
                //var arr=Object.values(this.zhichuData)
               var zhichu=[];
               var shouru=[]
                for(let x in cnum){
                    zhichu.push(res.data.payment[res.data.payment.length-1][cnum[x]])
                    shouru.push(res.data.receipt[res.data.receipt.length-1][cnum[x]])
                }
                zhichu.push(res.data.payment[res.data.payment.length-1]['总支出'])
                shouru.push(res.data.receipt[res.data.receipt.length-1]['总收入'])
                this.$refs.yearData.getMsg(zhichu,shouru);
            },
            changeYear(year){
                var zhichuData2=[]
                var shouruData2=[]
                for(let x in this.zhuchuData){
                    if(this.zhuchuData[x].年度==year){
                        zhichuData2=this.zhuchuData[x]
                        shouruData2=this.shouruData[x]
                    }
                }
                var cnum = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
                //var arr=Object.values(this.zhichuData)
                var zhichu=[];
                var shouru=[]
                for(let x in cnum){
                    zhichu.push(zhichuData2[cnum[x]])
                    shouru.push(shouruData2[cnum[x]])
                }
                zhichu.push(zhichuData2['总支出'])
                shouru.push(shouruData2['总收入'])
                this.$refs.yearData.getMsg(zhichu,shouru);
            },
            changeName(){
                this.proData2=[]
                for(let i in this.proData){
                    if(this.proData[i].projectName.includes(this.pronameSelect)){
                        this.proData2.push(this.proData[i])
                    }
                }
            },
            changeAddress(){
                this.proData2=[]
                for(let i in this.proData){
                    if(this.proData[i].projectAddress.includes(this.proAddressSelect)){
                        this.proData2.push(this.proData[i])
                    }
                }
            },
        },
        computed: {
            defaultOption () {
                return {
                    step: 0.2, // 数值越大速度滚动越快
                    limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
                }
            }
        },
        created(){
            this.getProtype()
            this.getpross();
            this.getProInfo();
            this.getTongji();
            this.getProApp()
           // this.getYear();
        },
        mounted(){
            this.initMap();
        },
        components: {
            homeEcharts,
            homeEchartsPro,
            homeEchartsShi,
            vueSeamlessScroll,
            jinduEcharts,
            homeEchartsTouzi
        },
    }
</script>

<style scoped lang="less">
    .bg{
        height: 100vh;
    }
    .homecontent{
        display: flex;
        justify-content:space-between;
        flex-wrap:wrap;
        margin: 20px 40px ;
        .contentLeft{
            display: flex;
            flex-wrap:wrap;
            width: 1210px;
            .contentTop{
                border-radius: 15px;
                width: 1210px;
                height:120px;
                display: flex;
                justify-content:space-between;
                border: 1px solid #E1E9E8;
                background-color: #4B88E3;
                div{
                    margin-left: 50px;
                    margin-right: 50px;
                    margin-top: 20px;
                    width: 200px;
                    p{
                        font-size: 24px;
                        font-weight: bold;
                        color: #fff;
                        margin-bottom: 10px;
                    }
                    span{
                        font-size: 17px;
                        color: #fff;
                    }
                    .pronum{
                        font-size: 32px;
                        font-weight: bold;
                        color: orange;
                        padding-right: 15px;
                    }
                    .prounit{
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
                .zong{
                    width: 280px;
                }
            }
            .contentCenter{
                border: 1px solid #E1E9E8;
                border-radius: 15px;
                margin-top: 10px;
                width: 1210px;
                height:450px;
                background-color: #fff;
                #map{
                    border-radius: 10px;
                    margin: 5px 10px;
                    width: 1190px;
                    height: 440px;
                }

            }
        }
        .contentRight{
            border: 1px solid #E1E9E8;
            border-radius: 15px;
            width: 590px;
            height:577px;
            .crad-body{
                display: flex;
                flex-wrap:  wrap;
                max-height:537px;
                overflow-y: auto;
                justify-content:center;
                .proDiv {
                    margin-top: 15px;
                    border-radius: 15px;
                    width: 520px;
                    height: 150px;
                    border: 1px solid #E1E9E8;
                    .proDivheader {
                        display: flex;
                        justify-content: space-between;
                        margin-left: 20px;
                        margin-right: 20px;
                        margin-top: 10px;
                        span {
                            max-width: 440px;
                            text-overflow:ellipsis;
                            -o-text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow:hidden;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                    .proDivcontent{
                        max-width: 480px;
                        white-space: nowrap;
                        text-overflow:ellipsis;
                        -o-text-overflow: ellipsis;
                        overflow:hidden;
                        margin-left:20px;
                        margin-right: 20px;
                        margin-top: 10px;
                        font-size: 16px;
                        .proTypeP{
                            display: flex;
                            justify-content: space-between;
                            margin-top: 5px;
                        }
                    }
                    .proDivbottom{
                        margin-top: 5px;
                        margin-left:20px;
                        margin-right: 20px;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap:  wrap;
                        font-size: 16px;
                        .processDiv{
                            width: 100%;height: 5px;background-color: #D6D6D6;border-radius: 10px;margin-top: 10px;
                            .realProcess{
                                height: 100%;background-color: #ff8676;border-radius: 1rem
                            }
                        }

                    }
                }
                .proDiv:hover{
                    background-color: #5598F1;
                    color: white;
                }
            }

        }
        .contentBottom{
            border: 1px solid #E1E9E8;
            border-radius: 15px;
            margin-top: 10px;
            width: 100%;
            height: 225px;
            background-color: #fff;
            .bottomHeader{
                display: flex;
                justify-content:space-between;
                margin-left: 20px;
                .zhu{
                    margin-top: 5px;
                    height: 25px;
                    border-left:6px solid #738FFC;
                    span{
                        line-height: 25px;
                        color: black;
                        font-weight: bold;
                        margin-left:10px;
                        font-size: 20px;
                    }
                    /deep/ .el-input__inner{
                        width: 100px;
                        height: 30px;
                    }
                }
                .choose{
                    margin-top: 5px;
                    margin-right: 20px;
                    z-index: 999;
                }
            }
            .bottomEcharts{
                margin-top: -30px;
                margin-left: 20px;
                height: 200px;
            }
        }
        .tuli{
            width: 160px;
            height: 100px;
            background-color: white;
            position: absolute;
            z-index: 999;
            border-radius: 15px;
            margin-left: 1020px;
            margin-top: 460px;
            padding-left: 7px;
            div{
                margin-top: 10px;
                img{
                    vertical-align: middle;height: 25px
                }
                span{
                    font-size: 16px;margin-left: 10px
                }
            }
            i{
                margin-left: 24px;
                font-size: 16px;
                vertical-align: -45%;
            }

        }
        .echartsDiv{
            width: 190px;
            height: 190px;
            position: absolute;
            z-index: 999;
            border-radius: 15px;
            margin-left: 1000px;
            margin-top: 160px;
            padding-left: 7px;
        }
    }

    /deep/ .el-card__body{
        padding: 0;
    }
    /deep/ .el-input__inner{
        border-radius: 20px;
        height: 35px;
        width: 250px;
    }
    .ol-popup {
        position: absolute;
        background-color: white;
        width: 200px;
        -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
        padding: 18px;
        padding-bottom:5px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 300px;
        /* background: rgba(59,88,119,1);
        color: #04F7F9;*/
        color: #000000;
    }
    .poptable{
        font-size: 14px;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        border: 1px solid #ccc;
        border-width: 0 1px 1px 0;
        border-collapse: collapse;
        border-spacing: 0;
        background-color: transparent;
        text-align: center;
    }
    .poptable tr td,.poptable tr th{
        line-height: 28px;
        font-size: 14px;
        padding: 0 8px;
        border: 1px solid #ccc;
        border-width: 1px 0 0 1px;
    }
    .ol-popup:after,
    .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;

    }
    .ol-popup:after {
        border-top-color: rgba(255,255,255,1);
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }

    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
    .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
    }

    .ol-popup-closer:after {
        content: "888";
    }

    .popx {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 4px 0 0;
        text-align: center;
        width: 18px;
        height: 14px;
        font: 16px/14px Tahoma, Verdana, sans-serif;
        color: #c3c3c3;
        text-decoration: none;
        font-weight: bold;
        background: transparent;
    }
    .seamless-warp{
        width: 50%;
        margin-left: 50px;
        margin-top:20px;
        height: calc(100% - 22px);
        overflow: hidden;
    }
    .seamless-warp2{
        width: 50%;
        margin-left: 250px;
        margin-top:20px;
        height: calc(100% - 22px);
        overflow: hidden;
    }
    /deep/ .el-badge__content{
        top: 10px !important;
        right: -1px !important;
    }
    .nianfen{
        margin-top: 5px;
        margin-left: 10px;
        z-index: 9999;
    }
    /deep/ .el-descriptions-item__label{
    font-size: 16px;
    font-weight: 777 !important;
}
/deep/ .el-descriptions-item__content{
    font-size: 16px;
}
.clearfix{
    display: flex;
    .el-input{
        width: 200px;
        margin-left: 10px;
        margin-top: -8px;
        /deep/ .el-input__inner{
            width: 200px !important;

        }
    }
}
</style>
