<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入制度文件名查询" v-model="queryInfo.rapexName" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="16">
                           <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">上传制度文件</el-button>

                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="filePath"
                                label="文件"
                                width="400"
                        >
                            <template v-slot="scope">
                                <a v-if="scope.row.rapexPath" style="cursor: pointer" @click="showfile(scope.row.rapexPath)">   {{scope.row.rapexName}}</a>
                                <span v-else>
                                    未上传制度文件
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="isEnabled"
                                label="文件级别"
                                sortable
                                width="240"
                                :show-overflow-tooltip="true"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.isEnabled==1">国家发文</span>
                                <span v-else-if="scope.row.isEnabled==2">兵团发文</span>
                                <span v-else-if="scope.row.isEnabled==3">集团发文</span>
                                <span v-else-if="scope.row.isEnabled==4">分院发文</span>
                                <span v-else>其它</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="regulateRapexTypeId"
                                label="文件类型"
                                sortable
                                width="240"
                                :show-overflow-tooltip="true"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.regulateRapexTypeId==1">规章制度</span>
                                <span v-else-if="scope.row.regulateRapexTypeId==2">通知公告</span>
                                <span v-else-if="scope.row.regulateRapexTypeId==3">责任书</span>
                                <span v-else-if="scope.row.regulateRapexTypeId==4">生产安全事故预案</span>
                                <span v-else>其它</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                label="上传时间"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="deleteMsg(scope.row.regulateRapexId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNum"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="上传制度文件"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addRules">

                <el-form-item label="文件类型" prop="regulateRapexTypeId">
                    <el-select v-model="addForm.regulateRapexTypeId" placeholder="请选择文件类型">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文件级别" prop="isEnabled">
                    <el-select v-model="addForm.isEnabled" placeholder="请选择文件级别">
                        <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="附件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :limit="1"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述；文件格式：PDF</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "notice",
        data(){
            return{
                tableData:[],
                proshow:false,
                queryInfo:{
                    rapexName:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    isEnabled:'',
                    departmentId:"",
                    regulateRapexTypeId:''
                },
                total:0,
                dialogVisible:false,
                addForm:{
                    isEnabled:'',
                    regulateRapexTypeId:'',
                    projectId:this.$root.proId,
                    rapexName:''
                },
                fileList:[],
                options:[{
                    value: 1,
                    label: '规章制度'
                },{
                    value: 2,
                    label: '通知公告'
                },{
                    value: 3,
                    label: '责任书'
                },{
                    value: 4,
                    label: '生产安全事故预案'
                },{
                    value: 5,
                    label: '其他'
                }],
                options2:[{
                    value: 1,
                    label: '国家发文'
                },{
                    value: 2,
                    label: '兵团发文'
                },{
                    value: 3,
                    label: '集团发文'
                },{
                    value: 4,
                    label: '分院发文'
                },{
                    value: 5,
                    label: '其它'
                }],
                addRules:{

                }
            }
        },
        created(){
            this.getFiles()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/regulate/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询文件制度失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addFile(){
                this.proshow=true
                var formData=new FormData();
                if(this.filelist){
                    formData.append("multipartFile",this.filelist[0].raw);
                }else {
                    formData.append("multipartFile",'');
                    return this.$message.error('请选择文件！')
                }
                formData.append("isEnabled",this.addForm.isEnabled);
                formData.append("regulateRapexTypeId",this.addForm.regulateRapexTypeId);
                formData.append("projectId",this.addForm.projectId);
                const {data:res}= await this.$http14.post('/v1.0/security/regulate/save',formData)
                if(res.status!='6000'){
                    return this.$message.error('上传制度文件失败！'+res.message)
                }
                this.$message.success('上传制度文件成功！');
                this.dialogVisible=false;
                this.proshow=false
                this.getFiles();
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该制度文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/regulate/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除制度文件失败！'+res.message)
                }
                this.$message.success('删除制度文件成功！');
                this.getFiles();
            },
           async showfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
               window.open(res2.data)
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=filelist
            },
            handlePreview(file) {
                console.log(file)
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNum=newPage;
                this.getFiles()
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">


</style>
